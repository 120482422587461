import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { settingsSelector } from '../../../store/core/core.store';
import { currentUserSelector, fetchProfile } from '../../../store/profile/profile.store';
import ProfileService from '../../../services/ProfileService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { resetEntries } from '../../../store/entry/entry.store';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	container: {
		paddingLeft: 50,
		'& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input': {
			color: theme.textLight,
		},
		'& .MuiInputLabel-formControl': {
			color: theme.textLight,
		},
	},
	langTextDark: {
		fontSize: theme.typography.pxToRem(16),
		flexBasis: '100%',
		flexShrink: 0,
		fontWeight: 'bold',
		color: theme.textDark,
		marginTop: 50,
		paddingLeft: 20,
	},
	langText: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.textLight,
		marginTop: 40,
	},
	selectBox: {
		width: 200,
		background: theme.modalColor,
		borderRadius: 10,
		color: theme.white,
		paddingRight: 20,
		paddingLeft: 20,
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 15,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	dropdown: {
		width: 200,
		background: 'rgb(166,166,166)',
		borderRadius: 10,
		color: theme.white,
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 5,
		position: 'absolute',
		zIndex: 99,
	},
	saveLang: {
		background: theme.mainBlue,
		borderRadius: 10,
		color: theme.white,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 10,
		paddingRight: 10,
		textAlign: 'center',
		fontSize: 14,
		marginTop: 10,
		width: 120,
		cursor: 'pointer',
	},
	dropdownItem: {
		paddingRight: 20,
		paddingLeft: 20,
		paddingBottom: 3,
		paddingTop: 3,
		'&:hover': {
			backgroundColor: theme.mainBlue,
		},
	},
	forms: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginTop: 30,
	},
	formsMulti: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
	},
	formControl: {
		width: 200,
		marginTop: 20,
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	progress: {
		color: theme.mainBlue,
	},
	saveContainer: {
		width: 200,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	textLight: {
		color: theme.textLight,
	},
	icon: {
		fill: theme.textLight,
		color: theme.textLight,
	},
	paper: {
		background: theme.mainBackgroundScreens,
		color: theme.textDark,
	},
}));

const languages = {
	en: 'english',
	de: 'german',
	es: 'Spanish',
	fr: 'French',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
export default function ChangeLanguage() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [language, setLanguage] = useState('');
	const [languageEntry, setLanguageEntry] = useState([]);
	const [loading, setLoading] = useState(false);
	const settings = useSelector(settingsSelector);
	const profile = useSelector(currentUserSelector);

	const { t } = useTranslation();

	const languageHandler = event => {
		setLanguage(languages[t(profile?.language)]);
		i18n.changeLanguage(event.target.value);
		localStorage.setItem('language', event.target.value);
		ProfileService.languageUpdate(event.target.value)
			.then(e => {
				// console.log(e);
			})
			.finally(() => {
				dispatch(fetchProfile());
			});
	};

	useEffect(() => {
		setLanguage(profile.language || '');
		setLanguageEntry(profile.entry_languages || []);
	}, [profile]);

	const entryLanguagesByKey = useMemo(
		() =>
			settings?.entry_languages?.reduce((acc, elem) => {
				acc[elem.code] = t(languages[elem.code]);
				return acc;
			}, {}),
		[settings?.entry_languages, language],
	);

	const handleChange = event => {
		dispatch(resetEntries());
		setLanguageEntry(event.target.value);
	};

	const handleSave = () => {
		setLoading(true);
		ProfileService.entryLanguagesUpdate(languageEntry)
			.then(e => {
				// console.log(e);
			})
			.finally(() => {
				dispatch(fetchProfile());
				setLoading(false);
			});
	};

	return (
		<div className={classes.container}>
			<div className={classes.forms}>
				<div className={classes.langText}>{t('select language web')}</div>
				<FormControl className={classes.formControl}>
					<InputLabel>
						{languages[language] && language ? t(languages[language]) : t('language')}
					</InputLabel>
					<Select
						value={languages[language]}
						onChange={languageHandler}
						MenuProps={{
							classes: {
								paper: classes.paper,
							},
						}}
						inputProps={{
							classes: {
								icon: classes.icon,
							},
						}}
					>
						<MenuItem value={'en'}>{t(languages['en'])}</MenuItem>
						<MenuItem value={'de'}>{t(languages['de'])}</MenuItem>
					</Select>
				</FormControl>
				{/*{settings?.entry_languages?.length > 1 && (*/}
				{/*	<>*/}
				{/*		<div className={classes.langText}>{t('select language entry')}</div>*/}
				{/*		<div className={classes.formsMulti}>*/}
				{/*			<FormControl className={classes.formControl}>*/}
				{/*				<InputLabel id="demo-mutiple-checkbox-label">{t('feed language')}</InputLabel>*/}
				{/*				<Select*/}
				{/*					labelId="demo-mutiple-checkbox-label"*/}
				{/*					id="demo-mutiple-checkbox"*/}
				{/*					multiple*/}
				{/*					value={languageEntry}*/}
				{/*					onChange={handleChange}*/}
				{/*					input={<Input />}*/}
				{/*					renderValue={selected => {*/}
				{/*						return selected*/}
				{/*							.map(val => (entryLanguagesByKey ? entryLanguagesByKey[val] : ''))*/}
				{/*							.filter(elem => !!elem)*/}
				{/*							.join(', ');*/}
				{/*					}}*/}
				{/*					MenuProps={MenuProps}*/}
				{/*					inputProps={{*/}
				{/*						classes: {*/}
				{/*							icon: classes.icon,*/}
				{/*						},*/}
				{/*					}}*/}
				{/*				>*/}
				{/*					{settings.entry_languages.map(val => (*/}
				{/*						<MenuItem key={val['code']} value={val['code']}>*/}
				{/*							<Checkbox checked={languageEntry.indexOf(val['code']) > -1} />*/}
				{/*							<ListItemText primary={t(languages[val['code']])} />*/}
				{/*							/!*<ListItemText primary={val['native_name']}/>*!/*/}
				{/*						</MenuItem>*/}
				{/*					))}*/}
				{/*				</Select>*/}
				{/*			</FormControl>*/}
				{/*			<div className={classes.saveContainer}>*/}
				{/*				{loading ? (*/}
				{/*					<CircularProgress className={classes.progress} />*/}
				{/*				) : (*/}
				{/*					<div className={classes.saveLang} onClick={() => handleSave()}>*/}
				{/*						{t('save')}*/}
				{/*					</div>*/}
				{/*				)}*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</>*/}
				{/*)}*/}
			</div>
		</div>
	);
}
