import React, { useEffect, useMemo } from 'react';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import Cloud from '../../../common/Cloud';
import ListItem from '../../../common/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Box from '@material-ui/core/Box';
import DoubleBalance from '../../shared/components/DoubleBalance';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchPoints,
	isRegisteredSelector,
	pointsSelector,
} from '../../../store/profile/profile.store';
import SectionHeading from '../../../common/SectionHeading';
import AppleStore from '../../../assets/apple-store.png';
import GooglePlay from '../../../assets/google-play.png';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { settingsSelector } from '../../../store/core/core.store';

const useStyles = makeStyles(theme => ({
	iconMobile: {
		width: 163,
	},
	imgContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: 10,
	},
}));

export default function OptionsScreen() {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(fetchPoints());
	}, [dispatch]);

	const points = useSelector(pointsSelector);
	const isRegistered = useSelector(isRegisteredSelector);
	const settings = useSelector(settingsSelector);

	const registrationLabel = useMemo(() => {
		let label = 'E-Mail und Passwort';
		if (!isRegistered) {
			label += ' (Nicht erstellt)';
		}
		return label;
	}, [isRegistered]);

	return (
		<div>
			<HeaderAppBar title={'Optionen'} leftIcon={<BackNavigationIcon />} rightIcon={null} />

			<Box p={[2]}>
				<Box pb={1} display={'flex'} justifyContent="flex-end">
					<DoubleBalance one={points.balance.earned + points.balance.bought} />
				</Box>
				<List component={Cloud}>
					<ListItem button divider onClick={() => history.replace('/profile')}>
						<div>{t('my profile')}</div>
						<ListItemSecondaryAction>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
					{/*<ListItem button divider onClick={() => history.push('/profile/options/membership')}>*/}
					{/*    <div>Meine Mitgliedschaft</div>*/}
					{/*    <ListItemSecondaryAction>*/}
					{/*        <ArrowForwardIosIcon fontSize={'inherit'}/>*/}
					{/*    </ListItemSecondaryAction>*/}
					{/*</ListItem>*/}
					<ListItem button divider onClick={() => history.push('/profile/manage')}>
						<div>{registrationLabel}</div>
						<ListItemSecondaryAction>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem button divider onClick={() => history.push('/profile/options/partner-link')}>
						<div>{t('my affiliate link2')}</div>
						<ListItemSecondaryAction>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem button divider onClick={() => history.push('/profile/options/points')}>
						<div>{t('my points')}</div>
						<ListItemSecondaryAction>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem button onClick={() => history.push('/profile/options/privacy')}>
						<div>{t('privacy')}</div>
						<ListItemSecondaryAction onClick={() => history.push('/profile/options/privacy')}>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
				</List>

				<SectionHeading py={1} pt={3}>
					Hilfe & Informationen
				</SectionHeading>
				<List component={Cloud}>
					<ListItem button divider onClick={() => history.push('/profile/options/contact')}>
						<div>{t('contact')}</div>
						<ListItemSecondaryAction>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem button divider onClick={() => history.push('/profile/options/faq')}>
						<div>FAQ</div>
						<ListItemSecondaryAction onClick={() => history.push('/profile/options/faq')}>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem>
						<div>Version</div>
						<ListItemSecondaryAction>
							<div style={{ color: '#666' }}>2.2.120</div>
						</ListItemSecondaryAction>
					</ListItem>
					<Divider />
					<ListItem button divider onClick={() => history.push('/animator')}>
						<div>{t('become entertainer')}</div>
						<ListItemSecondaryAction onClick={() => history.push('/animator')}>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
					<Divider />
					<a
						href={settings?.url_web?.gdpr}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: 'blue', textDecoration: 'none' }}
					>
						<ListItem>
							{t('register privacy')}
							<ListItemSecondaryAction>
								<ArrowForwardIosIcon fontSize={'inherit'} />
							</ListItemSecondaryAction>
						</ListItem>
					</a>
					<Divider />
					<a
						href={settings?.url_web?.terms}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: 'blue', textDecoration: 'none' }}
					>
						<ListItem>
							{t('register conditions')}
							<ListItemSecondaryAction>
								<ArrowForwardIosIcon fontSize={'inherit'} />
							</ListItemSecondaryAction>
						</ListItem>
					</a>
					<Divider />
					<ListItem onClick={() => history.push('/profile/options/license')}>
						<div style={{ color: 'blue' }}>LIZENZEN</div>
						<ListItemSecondaryAction>
							<ArrowForwardIosIcon fontSize={'inherit'} />
						</ListItemSecondaryAction>
					</ListItem>
					<Divider />
					<a
						href={settings?.url_web?.imprint}
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: 'blue', textDecoration: 'none' }}
					>
						<ListItem>
							{t('register imprint')}
							<ListItemSecondaryAction>
								<ArrowForwardIosIcon fontSize={'inherit'} />
							</ListItemSecondaryAction>
						</ListItem>
					</a>
				</List>
			</Box>
			<div
				style={{
					textAlign: 'center',
				}}
			>
				Heimlich ist auch verfügbar für Android und iOS!
			</div>
			<div className={classes.imgContainer}>
				<a
					href="https://itunes.apple.com/de/app/heimlich-com/id1308069339?ls=1&mt=8"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={AppleStore} alt="apple-store" className={classes.iconMobile} />
				</a>
				<a
					href="https://play.google.com/store/apps/details?id=com.heimlich"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img src={GooglePlay} alt="google-play" className={classes.iconMobile} />
				</a>
			</div>
		</div>
	);
}
