import React, {useCallback, useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import PublicRoute from './modules/shared/components/PublicRoute';
import PrivateRoute from './modules/shared/components/PrivateRoute';
import {PublicRoutes, PrivateRoutes} from './Routes';
import {useDispatch, useSelector} from 'react-redux';
import {authorize} from './store/profile/profile.store';
import {apiErrorEventTarget} from './modules/shared/events';
import {loadInitialBlockedUserIds} from './store/user/user.store';
import {loadInitialBlockedConversationIds} from './store/message/message.store';
import {fetchGlobalLoader, fetchSettings, fetchSettingsFailure, settingsSelector} from './store/core/core.store';
import NotificationStack from './common/NotificationStack';
import useStackNotification from './hooks/useStackNotification';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {ConfigCountry} from './helpers/configCountry';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import {useTranslation} from 'react-i18next';
import moment from 'moment-timezone';
import BottomToast from "./common/BottomToast";
import Loader from "./common/Loader";
import theme from "./theme";


export default function App() {
    const dispatch = useDispatch();
    const [apiError, setApiError] = useState(null);
    const [reload, setReload] = useState(false);
    const settings = useSelector(settingsSelector);
    const globalLoader = useSelector(state => state.core.globalLoader);
    const adc = useSelector(state => state.core);
    const {notifications, removeNotification} = useStackNotification();
    const {i18n} = useTranslation();
    const { t } = useTranslation();
    useEffect(() => {
        const lang = i18n.language === 'en' ? 'en-gb' : i18n.language;
        moment.locale(lang);
    }, [i18n.language]);

    useEffect(() => {
        dispatch(authorize());
        dispatch(loadInitialBlockedUserIds());
        dispatch(loadInitialBlockedConversationIds());
        // localStorage.getItem('darkMode') ? localStorage.setItem('darkMode', '0') : localStorage.setItem('darkMode', '1')
    }, [dispatch]);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);

    useEffect(() => {
        globalLoader && setTimeout(() => setReload(true), 10000)

    }, [])

    useEffect(()=>{
        reload && dispatch(fetchGlobalLoader());
    }, [reload])

    const apiErrorHandler = useCallback(
        event => {
            if (event.detail.statusCode === 401) {
            }
            setApiError(event.detail.message);
            window.hideApiError = () => setApiError(null);
        },
        [setApiError],
    );

    useEffect(() => {
        const {userLanguage, language} = window.navigator;
        const navigatorLang = (userLanguage || language).split('-')[0];
        const browserLang = settings?.languages?.find(element => element.code === navigatorLang);
        const storageLang = localStorage.getItem('language');

        let chosenLang;

        if (storageLang) {
            chosenLang = storageLang;
        } else if (browserLang) {
            chosenLang = browserLang.code;
        } else if (settings?.defaultLanguage) {
            chosenLang = settings?.defaultLanguage;
        } else {
            chosenLang = process.env.REACT_APP_DOMAIN === 'besecret' ? 'en' : 'de';
        }

        if (settings?.languages?.length) {
            localStorage.setItem('language', chosenLang);
        }

        i18n.changeLanguage(chosenLang);
    }, [settings]);

    useEffect(() => {
        apiErrorEventTarget.addEventListener('onApiError', apiErrorHandler);
        return () => {
            apiErrorEventTarget.removeEventListener('onApiError', apiErrorHandler);
        };
    }, [apiErrorHandler]);

    return (
        <GoogleOAuthProvider
            clientId={
                ConfigCountry.international
                    ? '254685056907-2ffrmaihncoblevb6rnp2tg8d5b0mh8j.apps.googleusercontent.com'
                    : '873731302484-mj6c0sms0stdgu3opt4iti6h4hj12bhn.apps.googleusercontent.com'
            }
        >
            <Router>
                {/*<BottomToast open={!!apiError} onClose={() => setApiError(null)}>*/}
                {/*    {apiError}*/}
                {/*</BottomToast>*/}
                {reload ? <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.mainBlue,
                    fontSize: 30
                }}>
                    {t('something went wrong')}...
                </div> : null}

                {globalLoader ?
                    <div style={{
                        width: '100%',
                        height: '100%',
                    }}>
                        <Loader/>
                    </div> :
                    <>
                        <NotificationStack notifications={notifications} removeNotification={removeNotification}/>
                        <Switch>
                            <PublicRoute path={'/auth'} component={PublicRoutes}/>
                            <PrivateRoute path={'/'} component={PrivateRoutes}/>
                        </Switch>
                    </>
                }

            </Router>
        </GoogleOAuthProvider>
    );
}
