import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import CommunityUserItem from '../../shared/components/CommunityUserItem';
import CommunityFilters from './CommunityFilters';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { resetUsers, searchUsers, usersSelector } from '../../../store/user/user.store';
import Loader from '../../../common/Loader';
import { useHistory } from 'react-router-dom';
import { currentUserSelector } from '../../../store/profile/profile.store';
import { countriesSelector } from '../../../store/core/core.store';
import { Typography } from '@material-ui/core';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from '@material-ui/core/Collapse';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		justifyContent: 'space-around',
		overflow: 'hidden',
	},
	gridList: {
		padding: '12px 40px 0',
		display: 'flex',
		justifyContent: 'left',
		[theme.breakpoints.down('md')]: {
			padding: 0,
		},
	},
	gridListTitle: {},
	icon: {
		color: 'rgba(255, 255, 255, 0.54)',
	},
	messageText: {
		textAlign: 'center',
		padding: '20px 0',
		color: theme.textDark,
	},
	communityHeader: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 30,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	icons: {
		fontSize: 16,
		marginLeft: 10,
		color: theme.mainBlue,
	},
	searchRowOne: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingRight: '4%',
		flex: 1,
		cursor: 'pointer',
	},
	searchRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-end',
		paddingRight: '4%',
		flex: 1,
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
			margin: '10px 0',
		},
	},
	headerTitle: {
		flex: 2,
		textAlign: 'center',
		fontSize: 20,
		color: theme.headerIcon,
	},
}));
export default function CommunityUser() {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { width } = useWindowSize();
	const profile = useSelector(currentUserSelector);
	const initialState = () =>
		JSON.parse(window.localStorage.getItem('lastFilter')) || {
			nickname: null,
			agefrom: 18,
			ageto: 99,
			gender: profile?.gender === 'w' ? 'm' : 'w',
			country: profile?.country,
			zip: '',
		};
	const [filters, setFilters] = useState(initialState);
	const [userMatch, setUserMatch] = useState(false);
	const users = useSelector(usersSelector);
	const page = useSelector(state => state.user.page);
	const isLoading = useSelector(state => state.user.isLoading);
	const hasMore = useSelector(state => state.user.hasMore);
	const [showFilters, setShowFilters] = useState(false);

	const countries = useSelector(countriesSelector);

	const loadProducer = useCallback(() => {
		let clearFilter = filters;
		if (filters?.zip === 'Egal') {
			clearFilter.zip = null;
		}
		dispatch(
			searchUsers({
				...clearFilter,
				page,
			}),
		);
	}, [dispatch, filters, page]);

	useEffect(() => {
		dispatch(resetUsers());
		window.localStorage.setItem('lastFilter', JSON.stringify(filters));
	}, [filters, dispatch]);

	useEffect(() => {
		if (page === 1) {
			loadProducer();
		}
	}, [page, loadProducer]);

	if (!users) {
		return <Loader />;
	}

	const onFiltersApply = freshFilters => {
		setFilters(currentFilters => ({
			...currentFilters,
			...freshFilters,
		}));
		setUserMatch(true);
		setShowFilters(false);
	};

	const goToUser = id => {
		history.push(profile.uid === id ? '/profile' : `/community/${id}`);
	};

	const getItemColl = () => {
		return width < 600 ? 2 : (width - (width % 200) - 100) / 200;
	};

	return (
		<div className={classes.root}>
			{/*{users?.length &&*/}
			<div className={classes.communityHeader}>
				<div className={classes.searchRowOne} />
				<div className={classes.headerTitle}>{t('secret community')}</div>
				<div className={classes.searchRow} onClick={() => setShowFilters(!showFilters)}>
					<div className={classes.icons}>{showFilters ? t('close filter') : t('open filter')}</div>
					<FontAwesomeIcon icon={faSearch} className={classes.icons} color={theme.headerIcon} />
				</div>
			</div>
			{/*}*/}
			<Collapse in={showFilters} timeout={700} disableStrictModeCompat={true}>
				<CommunityFilters
					nickname={filters.nickname || ''}
					gender={filters.gender}
					agefrom={filters.agefrom}
					ageto={filters.ageto}
					country={filters.country}
					zip={filters.zip}
					countries={countries}
					onApply={onFiltersApply}
				/>
			</Collapse>

			<InfiniteScroll initialLoad={false} loadMore={loadProducer} hasMore={hasMore && !isLoading}>
				{users?.length > 0 && (
					<div
						style={{
							width: '90%',
							margin: '0 auto',
							[theme.breakpoints.down('md')]: {
								width: '65%',
							},
						}}
					>
						<GridList
							cellHeight={'auto'}
							cols={getItemColl()}
							spacing={30}
							className={classes.gridList}
						>
							{users.map((user, index) => (
								<div className={classes.userItem} key={index} onClick={() => goToUser(user.uid)}>
									<CommunityUserItem
										nickname={user.nickname}
										gender={user.gender}
										age={user.age}
										avatar={user.avatar}
									/>
								</div>
							))}
						</GridList>
					</div>
				)}
			</InfiniteScroll>
			{users?.length === 0 && userMatch && (
				<div className={classes.messageText}>
					<Typography>{t('nothing found')}</Typography>
				</div>
			)}
			{isLoading ? <Loader /> : null}
		</div>
	);
}
