import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import femalePink from '../../../assets/ic_female_pink-web.png';
import maleBlue from '../../../assets/ic_male_blue-web.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	user: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		cursor: 'pointer',
		border: '1px solid #ededed',
		borderRadius: 5,
		padding: '20px 0',
	},
	nickname: {
		color: theme.mainBlue,
		marginTop: '10px',
		fontWeight: '600',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		width: '100%',
		textAlign: 'center',
	},
	media: {
		height: 100,
		maxWidth: 100,
		borderRadius: '50%',
	},
	userDescription: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	age: {
		backgroundColor: '#c1c1c1',
		padding: 3,
		borderRadius: 3,
		margin: '0 5px',
	},
	gender: {
		width: 20,
		height: 20,
		marginLeft: 5,
	},
}));

export default function CommunityUserItem({ nickname, gender, avatar, age }) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.user}>
			<img alt={'avatar'} src={avatar} className={classes.media} />
			<Typography className={classes.nickname} color={'primary'}>
				{nickname}
			</Typography>
			<div className={classes.userDescription}>
				<Typography variant={'subtitle2'} className={classes.age}>
					{age}
					{t('profile age')}
				</Typography>
				{gender === 'w' && <img alt={'gender'} src={femalePink} className={classes.gender} />}
				{gender === 'm' && <img alt={'gender'} src={maleBlue} className={classes.gender} />}
			</div>
		</div>
	);
}
