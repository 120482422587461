import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faGlobe } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../i18n';
import { makeStyles } from '@material-ui/core/styles';

const langObj = [
	{ code: 'en', name: 'English', native_name: 'English' },
	{ code: 'de', name: 'German', native_name: 'Deutsch' },
];

const useStyles = makeStyles(theme => ({
	selectBox: {
		background: theme.modalColor,
		borderRadius: 10,
		color: theme.white,
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 15,
		marginLeft: 10,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	dropdown: {
		width: 'max-content',
		background: theme.modalColor,
		borderRadius: 10,
		color: theme.white,
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 5,
		marginLeft: 10,
		position: 'absolute',
		zIndex: 99,
	},
	dropdownItem: {
		paddingRight: 20,
		paddingLeft: 20,
		paddingBottom: 3,
		paddingTop: 3,
		'&:hover': {
			backgroundColor: theme.mainBlue,
		},
	},
	icons: {
		marginLeft: 10,
		marginRight: 10,
	},
}));

function Language() {
	const [select, setSelect] = useState(false);
	const classes = useStyles();
	const [language, setLanguage] = useState('');

	useEffect(() => {
		setLanguage(localStorage.getItem('language'));
	}, [localStorage.getItem('language')]);

	function handleLanguageChange(lang) {
		i18n.changeLanguage(lang);
		localStorage.setItem('language', lang);
		setSelect(false);
	}

	return (
		<div style={{ position: 'fixed', top: 0, right: 15, cursor: 'pointer' }}>
			<div className={classes.selectBox} onClick={() => setSelect(prev => !prev)}>
				<FontAwesomeIcon icon={faGlobe} className={classes.icons} />
				<div>{langObj?.find(val => val.code === language)?.native_name}</div>
				<FontAwesomeIcon icon={!select ? faChevronDown : faChevronUp} className={classes.icons} />
			</div>
			{select && (
				<div className={classes.dropdown}>
					{langObj?.map(val => (
						<div onClick={() => handleLanguageChange(val.code)} className={classes.dropdownItem}>
							{val.native_name}
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default Language;
