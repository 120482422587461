import React, { Fragment, useState } from 'react';
import { Input, makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Cloud from '../../../common/Cloud';
import PaperButton from '../../shared/components/PaperButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import VideocamIcon from '@material-ui/icons/Videocam';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SectionHeading from '../../../common/SectionHeading';
import SubmitButton from './SubmitButton';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { settingsSelector } from '../../../store/core/core.store';

const useStyles = makeStyles(theme => ({
	mediaBoxContainer: {
		display: 'flex',
		padding: 7,
	},
	mediaBoxItem: {
		position: 'relative',
		flex: 1,
		padding: '0 4px 0 4px',
	},
	label: {
		fontSize: '0.9rem',
	},
	labelPlacementStart: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
		justifyContent: 'space-between',
	},
	captureButton: {
		position: 'absolute',
		left: '40%',
		bottom: 100,
		zIndex: 1,
	},
	mediaOptionItem: {
		position: 'relative',
	},
	mediaOptionIcon: {
		position: 'absolute',
		top: 7,
		left: 11,
		color: theme.palette.primary.user_red,
	},
	sendButton: {
		backgroundColor: '#29a646',
		'&:hover': {
			backgroundColor: 'green',
		},
	},
	formControl: {
		width: 200,
		marginBottom: 20,
	},
}));

const languages = {
	en: 'English',
	de: 'Deutsch',
};

export default function MediaForm({
	onTextChange: handleTextChange,
	onFileChange: handleFileChange,
	onShareSocialChange: handleShareSocialChange,
	onSubmit: handleSubmit,
	mediaType,
	shareSocial,
	handleGetLanguageCode,
	flow,
	message,
}) {
	const classes = useStyles();
	const { t } = useTranslation();
	const settings = useSelector(settingsSelector);
	const isPostFlow = flow === 'post';

	const messageLabel = isPostFlow ? t('post some secret') : t('describe problem');
	const mediaLabel = isPostFlow ? t('add image or video') : t('add screenshot');
	const submitText = isPostFlow ? t('add entry') : t('send your message');
	const messageRows = isPostFlow ? 10 : 14;

	const [language, setLanguage] = useState();

	const languageHandler = event => {
		handleGetLanguageCode(event.target.value);
		setLanguage(languages[event.target.value]);
	};
	return (
		<>
			<Box p={1} style={{ backgroundColor: theme.mainBackgroundScreens }}>
				<SectionHeading pl={1}>{messageLabel}</SectionHeading>
				<Box component={Cloud} mb={2}>
					<Input
						fullWidth
						multiline
						rows={messageRows}
						defaultValue=""
						placeholder={t('description')}
						disableUnderline
						onChange={handleTextChange}
						value={message}
						style={{ color: theme.textLight }}
					/>
				</Box>
				{/*<FormControl className={classes.formControl}>*/}
				{/*    <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel>*/}
				{/*    <Select*/}
				{/*        labelId="demo-simple-select-label"*/}
				{/*        id="demo-simple-select"*/}
				{/*        value={language}*/}
				{/*        onChange={languageHandler}*/}
				{/*    >*/}
				{/*        {settings.entry_languages.map((val) => (*/}
				{/*            <MenuItem key={val} value={val['code']}>{val['native_name']}</MenuItem>*/}
				{/*        ))}*/}
				{/*    </Select>*/}
				{/*</FormControl>*/}
				<SectionHeading pl={1}>{mediaLabel}</SectionHeading>
				<Box component={Cloud} className={classes.mediaBoxContainer}>
					<Box className={classes.mediaBoxItem}>
						<PaperButton
							id={'photo'}
							name="pic"
							accept="image/*"
							label={t('add image')}
							icon={<PhotoCameraIcon />}
							onChange={event => handleFileChange('image', event)}
						/>
						{mediaType === 'image' ? (
							<CheckCircleIcon fontSize={'small'} className={classes.mediaOptionIcon} />
						) : null}
					</Box>
					{/*{isPostFlow ? (*/}
					{/*	<Box className={classes.mediaBoxItem}>*/}
					{/*		<PaperButton*/}
					{/*			id={'video'}*/}
					{/*			name="vid"*/}
					{/*			accept="video/*"*/}
					{/*			label={t('add video')}*/}
					{/*			icon={<VideocamIcon />}*/}
					{/*			onChange={event => handleFileChange('video', event)}*/}
					{/*		/>*/}
					{/*		{mediaType === 'video' ? (*/}
					{/*			<CheckCircleIcon fontSize={'small'} className={classes.mediaOptionIcon} />*/}
					{/*		) : null}*/}
					{/*	</Box>*/}
					{/*) : null}*/}
				</Box>
			</Box>
			{isPostFlow ? (
				<FormControlLabel
					classes={{
						label: classes.label,
						labelPlacementStart: classes.labelPlacementStart,
					}}
					labelPlacement={'start'}
					control={
						<Checkbox
							style={{
								color: '#29a646',
								width: 40,
								height: 30,
								'&$checked': {
									color: '#e1e1e1',
								},
							}}
							name={'agreement'}
							checked={shareSocial || false}
							onChange={handleShareSocialChange}
							value="checked"
							color={'#29a646'}
						/>
					}
					label={
						<Grid container style={{ color: theme.textLight }}>
							<Grid item xs={12}>
								<Typography component={'div'}>
									<Box pl={2} fontWeight="fontWeightBold" fontSize={13} lineHeight={1.5}>
										{t('share in socials')}
									</Box>
								</Typography>
								<Typography component={'div'}>
									<Box pl={2} fontSize={11} lineHeight={1.5}>
										({t('post will be shared')})
									</Box>
								</Typography>
							</Grid>
						</Grid>
					}
				/>
			) : null}
			<Box pl={1} pr={1} mt={1} mb={1}>
				<SubmitButton style={classes.sendButton} onClick={handleSubmit}>
					{submitText}
				</SubmitButton>
			</Box>
			{isPostFlow ? (
				<Typography component={'div'} style={{ color: theme.textLight }}>
					<Box pl={2} pr={2} fontSize={11} textAlign={'center'} lineHeight={1.5}>
						{t('confirm post')}
					</Box>
				</Typography>
			) : null}
		</>
	);
}
