import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import CommunityUserList from '../components/CommunityUserList';
import HeaderRight from '../../shared/components/HedaerRight';
import { useHistory } from 'react-router-dom';
import theme from '../../../theme';
import { fetchSettings } from '../../../store/core/core.store';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.mainBackgroundScreens,
		height: '101%',
	},
	logo: {
		height: 40,
	},
}));

export default function CommunityScreen() {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchSettings());
		// localStorage.getItem('darkMode') ? localStorage.setItem('darkMode', '0') : localStorage.setItem('darkMode', '1')
	}, []);
	return (
		<div className={classes.root}>
			<HeaderAppBar
				leftIcon={
					<img
						src={theme.smallLogo}
						alt={'logo'}
						className={classes.logo}
						onClick={() => history.push('/feed')}
						color={'action'}
					/>
				}
				rightIcon={<HeaderRight />}
				newPost
				isLogo
				bar
			/>
			<CommunityUserList />
		</div>
	);
}
