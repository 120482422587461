import { createAction, createReducer } from 'redux-starter-kit';
import EntryService from '../../services/EntryService';

import { pipe, path } from 'ramda';
import { findById } from '../../helpers/fp';

export const fetchCategoriesRequest = createAction('FETCH_CATEGORIES_REQUEST');
export const fetchCategoriesFailure = createAction('FETCH_CATEGORIES_FAILURE');
export const fetchCategoriesSuccess = createAction('FETCH_CATEGORIES_SUCCESS');

export const searchEntriesRequest = createAction('SEARCH_ENTRIES_REQUEST');
export const searchEntriesFailure = createAction('SEARCH_ENTRIES_FAILURE');
export const searchEntriesSuccess = createAction('SEARCH_ENTRIES_SUCCESS');
export const searchFirstEntriesSuccess = createAction('SEARCH_FIRST_ENTRIES_SUCCESS');

export const searchUserEntriesRequest = createAction('SEARCH_USER_ENTRIES_REQUEST');
export const searchUserEntriesFailure = createAction('SEARCH_USER_ENTRIES_FAILURE');
export const searchUserEntriesSuccess = createAction('SEARCH_USER_ENTRIES_SUCCESS');

export const voteEntryRequest = createAction('VOTE_ENTRY_REQUEST');
export const voteEntryFailure = createAction('VOTE_ENTRY_FAILURE');
export const voteEntrySuccess = createAction('VOTE_ENTRY_SUCCESS');

export const blockEntryRequest = createAction('BLOCK_ENTRY_REQUEST');
export const blockEntryFailure = createAction('BLOCK_ENTRY_FAILURE');
export const blockEntrySuccess = createAction('BLOCK_ENTRY_SUCCESS');

export const deleteEntryPostRequest = createAction('DELETE_ENTRY_POST');
export const deleteEntryPostSuccess = createAction('DELETE_ENTRY_POST_SUCCESS');

export const loadInitialBlockedEntryIdsRequest = createAction(
	'LOAD_INITIAL_BLOCK_ENTRY_IDS_REQUEST',
);
export const loadInitialBlockedEntryIdsFailure = createAction(
	'LOAD_INITIAL_BLOCK_ENTRY_IDS_FAILURE',
);
export const loadInitialBlockedEntryIdsSuccess = createAction(
	'LOAD_INITIAL_BLOCK_ENTRY_IDS_SUCCESS',
);

export const getEntryRequest = createAction('GET_ENTRY_REQUEST');
export const getEntryFailure = createAction('GET_ENTRY_FAILURE');
export const getEntrySuccess = createAction('GET_ENTRY_SUCCESS');

export const updateEntry = createAction('UPDATE_ENTRY');

export const resetEntries = createAction('RESET_ENTRIES');
export const resetUserEntries = createAction('RESET_USER_ENTRIES');

const initialState = {
	entries: [],
	isEntriesLoading: false,
	entriesPage: 0,
	entriesHasMore: false,

	userEntries: [],
	isUserEntriesLoading: false,
	userEntriesPage: 0,
	userEntriesHasMore: false,

	categories: [],
	isCategoriesLoading: false,
	isVoting: false,

	blockedEntryIds: [],
	isBlocking: false,

	entry: null,
	isPostDeleting: false,
};

export const fetchCategories = () => async dispatch => {
	dispatch(fetchCategoriesRequest());

	EntryService.getCategories()
		.then(({ data }) => {
			dispatch(
				fetchCategoriesSuccess({
					categories: data.data,
				}),
			);
		})
		.catch(() => {
			dispatch(fetchCategoriesFailure());
		});
};

export const searchEntries =
	({ page, type, categoryId }) =>
	dispatch => {
		dispatch(searchEntriesRequest());
		EntryService.searchEntries({ type, page, categoryId })
			.then(({ data }) => {
				dispatch(
					searchEntriesSuccess({
						entries: data.entries.data,
						page,
						hasMore: data.entries.last_page > page,
					}),
				);
			})
			.catch(() => {
				dispatch(searchEntriesFailure());
			});
	};

export const searchFirstEntries =
	({ page, type, categoryId }) =>
	dispatch => {
		dispatch(searchEntriesRequest());
		EntryService.searchEntries({ type, page, categoryId })
			.then(({ data }) => {
				dispatch(
					searchFirstEntriesSuccess({
						entries: data.entries.data,
						page,
						hasMore: data.entries.last_page > page,
					}),
				);
			})
			.catch(() => {
				dispatch(searchEntriesFailure());
			});
	};

export const getEntry = entryId => dispatch => {
	dispatch(getEntryRequest());
	EntryService.getEntry(entryId)
		.then(({ data }) => {
			dispatch(
				getEntrySuccess({
					entry: data,
				}),
			);
		})
		.catch(() => {
			dispatch(getEntryFailure());
		});
};

export const searchUserEntries =
	({ page, type, uid }) =>
	dispatch => {
		dispatch(searchUserEntriesRequest());
		EntryService.searchUserEntries({ type, page, uid })
			.then(({ data }) => {
				dispatch(
					searchUserEntriesSuccess({
						entries: data.data,
						page,
						hasMore: data.last_page > page,
					}),
				);
			})
			.catch(() => {
				dispatch(searchUserEntriesFailure());
			});
	};

export const voteEntry =
	({ id, rate }) =>
	dispatch => {
		dispatch(voteEntryRequest());
		EntryService.vote(id, rate)
			.then(({ data }) => {
				dispatch(voteEntrySuccess());
				dispatch(updateEntry({ ...data.data, id, ownvote: rate }));
			})
			.catch(() => {
				dispatch(voteEntryFailure());
			});
	};

export const blockEntry =
	({ id }) =>
	dispatch => {
		dispatch(blockEntryRequest());
		EntryService.blockEntry(id)
			.then(() => {
				dispatch(blockEntrySuccess({ id }));
			})
			.catch(() => {
				dispatch(blockEntryFailure());
			});
	};

export const deletePostEntry =
	({ id }) =>
	dispatch => {
		dispatch(deleteEntryPostRequest());
		EntryService.deletePostEntry(id)
			.then(suc => {
				dispatch(updateEntry());
			})
			.catch(err => {});
	};

export const loadInitialBlockedEntryIds = () => dispatch => {
	dispatch(loadInitialBlockedEntryIdsRequest());
	EntryService.getBlockedEntryIds()
		.then(ids => {
			dispatch(loadInitialBlockedEntryIdsSuccess({ ids }));
		})
		.catch(() => {
			dispatch(loadInitialBlockedEntryIdsFailure());
		});
};

const entryReducer = createReducer(initialState, {
	// Fetch Categories
	[fetchCategoriesRequest]: state => ({ ...state, isCategoriesLoading: true }),
	[fetchCategoriesSuccess]: (state, { payload }) => ({
		...state,
		isCategoriesLoading: false,
		categories: payload.categories,
	}),
	[fetchCategoriesFailure]: state => ({ ...state, isCategoriesLoading: false }),

	// Search Entries
	[searchEntriesRequest]: state => ({ ...state, isEntriesLoading: true }),
	[searchEntriesSuccess]: (state, { payload }) => ({
		...state,
		isEntriesLoading: false,
		entries: [...state.entries, ...payload.entries],
		entriesPage: payload.page,
		entriesHasMore: payload.hasMore,
	}),
	[searchFirstEntriesSuccess]: (state, { payload }) => ({
		...state,
		isEntriesLoading: false,
		entries: payload.entries,
		entriesPage: payload.page,
		entriesHasMore: payload.hasMore,
	}),
	[searchEntriesFailure]: state => ({ ...state, isEntriesLoading: false }),

	// Search User Entries
	[searchUserEntriesRequest]: state => ({ ...state, isUserEntriesLoading: true }),
	[searchUserEntriesSuccess]: (state, { payload }) => ({
		...state,
		isUserEntriesLoading: false,
		userEntries: [...state.userEntries, ...payload.entries],
		userEntriesPage: payload.page,
		userEntriesHasMore: payload.hasMore,
	}),
	[searchUserEntriesFailure]: state => ({ ...state, isUserEntriesLoading: false }),

	// Reset Entries
	[resetUserEntries]: state => ({
		...state,
		userEntriesHasMore: false,
		userEntries: [],
		isUserEntriesLoading: false,
		userEntriesPage: 0,
	}),

	// Vote Entry
	[voteEntryRequest]: state => ({ ...state, isVoting: true }),
	[voteEntrySuccess]: state => ({ ...state, isVoting: false }),
	[voteEntryFailure]: state => ({ ...state, isVoting: false }),

	// Block Entry
	[blockEntryRequest]: state => ({ ...state, isBlocking: true }),
	[blockEntrySuccess]: (state, { payload }) => ({
		...state,
		isBlocking: false,
		blockedEntryIds: [...state.blockedEntryIds, payload.id],
	}),
	[blockEntryFailure]: state => ({ ...state, isBlocking: false }),

	// Post Delete
	[deleteEntryPostRequest]: state => ({ ...state, isPostDeleting: true }),
	[deleteEntryPostSuccess]: state => ({ ...state, isPostDeleting: false }),

	// Load Initial Blocked Entry Ids
	[loadInitialBlockedEntryIdsRequest]: state => ({ ...state }),
	[loadInitialBlockedEntryIdsSuccess]: (state, { payload }) => ({
		...state,
		blockedEntryIds: payload.ids,
	}),
	[loadInitialBlockedEntryIdsFailure]: state => ({ ...state }),

	// View Entry
	[getEntryRequest]: state => ({ ...state, isEntriesLoading: true }),
	[getEntrySuccess]: (state, { payload }) => ({
		...state,
		isEntriesLoading: false,
		entry: payload.entry,
	}),
	[getEntryFailure]: state => ({ ...state, isEntriesLoading: false }),

	[updateEntry]: (state, { payload }) => ({
		...state,
		entries: state.entries.map(entry => {
			if (entry.id === payload.id) {
				return {
					...entry,
					...payload,
					voted: true,
				};
			}
			return entry;
		}),
	}),

	// Reset Entries
	[resetEntries]: state => ({
		...state,
		entriesHasMore: false,
		entries: [],
		isEntriesLoading: false,
		entriesPage: 0,
	}),
});

const stateProp = key => path(['entry', key]);

export const categoriesSelector = stateProp('categories');
export const categorySelector = id => pipe(categoriesSelector, findById(id));

export const entriesSelector = stateProp('entries');
export const isEntriesLoadingSelector = stateProp('isEntriesLoading');
export const entriesPageSelector = stateProp('entriesPage');
export const entriesHasMoreSelector = stateProp('entriesHasMore');

export const userEntriesSelector = stateProp('userEntries');
export const isUserEntriesLoadingSelector = stateProp('isUserEntriesLoading');
export const userEntriesPageSelector = stateProp('userEntriesPage');
export const userEntriesHasMoreSelector = stateProp('userEntriesHasMore');

export const isPostDeletingSelector = stateProp('isPostDeleting');

export const isVotingSelector = stateProp('isVoting');
export const isEntryBlockingSelector = stateProp('isBlocking');
export const blockedEntryIdsSelector = stateProp('blockedEntryIds');
export const entrySelector = stateProp('entry');

export default entryReducer;
