import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	makeStyles,
	BottomNavigationAction,
	AppBar,
	Badge,
	BottomNavigation,
} from '@material-ui/core';
import {
	unreadConversationsSelector,
	loadUnreadConversationsCount,
	unreadNotificationsSelector,
	loadUnreadNotificationsCount,
} from '../../../store/chat/chat.store';
import NotificationStack from '../../../common/NotificationStack';

import useStackNotification from '../../../hooks/useStackNotification';
import { resetEntries } from '../../../store/entry/entry.store';
import { faBell, faHome, faComment, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyAvatar from './MyAvatar';

const useStyles = makeStyles(theme => ({
	appBar: {
		top: 'auto',
		bottom: 0,
	},
	navigationItem: {
		fontSize: 25,
		color: theme.headerIcon,
		'&$selected': {
			color: theme.mainBlue,
		},
	},
	bottomNavigation: {
		backgroundColor: theme.headerBackground,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	bottomNavigationContainer: {
		backgroundColor: theme.headerBackground,
	},
	icons: {
		fontSize: 25,
	},
	selected: {},
}));

export default function PrivateNavigation({ children }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	let location = useLocation();

	const { notifications, removeNotification } = useStackNotification();

	const unreadConversations = useSelector(unreadConversationsSelector);
	const unreadNotifications = useSelector(unreadNotificationsSelector);

	useEffect(() => {
		dispatch(loadUnreadConversationsCount());
		dispatch(loadUnreadNotificationsCount());
	}, [dispatch]);

	const handleChange = (event, newValue) => {
		history.push(newValue);
		if (location.pathname.includes('feed') && location.pathname.split('/').length > 2) {
			dispatch(resetEntries());
		}
	};

	const routePrefix = `/${history.location.pathname.split('/')[1]}`;

	return (
		<div className={classes.bottomNavigation}>
			{/*<NotificationStack notifications={notifications} removeNotification={removeNotification}/>*/}
			{children}
			{!location.pathname.includes('/conversations/') && !location.pathname.includes('comments') && (
				<Fragment>
					<AppBar position="fixed" className={classes.appBar}>
						<BottomNavigation
							value={routePrefix}
							onChange={handleChange}
							showLabels
							className={classes.bottomNavigationContainer}
						>
							<BottomNavigationAction
								classes={{ root: classes.navigationItem, selected: classes.selected }}
								value="/feed"
								icon={<FontAwesomeIcon icon={faHome} />}
							/>
							<BottomNavigationAction
								classes={{ root: classes.navigationItem, selected: classes.selected }}
								value="/community"
								icon={<FontAwesomeIcon icon={faUsers} />}
							/>
							<BottomNavigationAction
								classes={{ root: classes.navigationItem, selected: classes.selected }}
								value="/conversations"
								icon={
									<Badge badgeContent={unreadConversations} color="primary">
										<FontAwesomeIcon icon={faComment} />
									</Badge>
								}
							/>
							<BottomNavigationAction
								classes={{ root: classes.navigationItem, selected: classes.selected }}
								value="/notifications"
								icon={
									<Badge badgeContent={unreadNotifications} color="primary">
										<FontAwesomeIcon icon={faBell} />
									</Badge>
								}
							/>
							<BottomNavigationAction
								classes={{ root: classes.navigationItem }}
								value="/profile"
								icon={<MyAvatar size={30} withBorder={false} />}
							/>
						</BottomNavigation>
					</AppBar>
				</Fragment>
			)}
		</div>
	);
}
