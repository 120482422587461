import React, { useEffect } from 'react';
import EntryList from '../components/entry/EntryList';
import { useDispatch, useSelector } from 'react-redux';
import { categorySelector, fetchCategories } from '../../../store/entry/entry.store';
import { useHistory, useParams } from 'react-router-dom';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import Box from '@material-ui/core/Box';
import useEntries from '../hooks/useEntries';
import Loader from '../../../common/Loader';
import { ENTRY_TYPE } from '../../../constants';
import HeaderRight from '../../shared/components/HedaerRight';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../theme';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.mainBackgroundScreens,
		paddingBottom: 64,
	},
	indicator: {
		backgroundColor: 'transparent',
		justifyContent: 'center',
	},
	tabStyle: {
		backgroundColor: theme.mainBlue,
		borderRadius: 9,
		margin: '28px 50px 0',
		minHeight: 'auto',
		color: theme.white,
		padding: '4px 60px',
		[theme.breakpoints.down('md')]: {
			margin: '15px 6px 0',
			padding: '4px 15px',
			fontSize: 13,
		},
	},
}));

export default function FeedCategoryEntriesScreen() {
	const dispatch = useDispatch();
	const { categoryId } = useParams();
	const category = useSelector(categorySelector(Number(categoryId)));
	const classes = useStyles();
	const history = useHistory();

	useEffect(() => {
		dispatch(fetchCategories());
	}, [dispatch]);

	const { isLoading, hasMore, entries, loadNext } = useEntries({
		type: ENTRY_TYPE.BY_CATEGORY,
		categoryId,
	});

	return (
		<div className={classes.root}>
			<HeaderAppBar
				positionHeader={'fixed'}
				leftIcon={
					<img
						src={theme.smallLogo}
						alt={'logo'}
						className={classes.logo}
						onClick={() => history.push('/feed')}
						color={'action'}
					/>
				}
				rightIcon={<HeaderRight />}
				newPost
				isLogo
				bar
			/>
			<HeaderAppBar
				leftIcon={<BackNavigationIcon tabIndex={2} removeEntry />}
				title={category ? category.title : null}
				rightIcon={null}
				secondHeader
			/>
			{isLoading ? <Loader /> : null}

			<Box p={1} style={{ marginTop: 65 }}>
				<EntryList isLoading={isLoading} entries={entries} hasMore={hasMore} loadMore={loadNext} />
			</Box>
		</div>
	);
}
