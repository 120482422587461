import React, { useEffect } from 'react';
import useEntries from '../hooks/useEntries';
import EntryList from './entry/EntryList';
import Box from '@material-ui/core/Box';
import { ENTRY_TYPE } from '../../../constants';
import { searchFirstEntries } from '../../../store/entry/entry.store';
import { useDispatch } from 'react-redux';

export default function NewestEntries() {
	const { isLoading, hasMore, entries, loadNext } = useEntries({ type: ENTRY_TYPE.NEWEST });
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(searchFirstEntries({ page: 1, type: ENTRY_TYPE.NEWEST }));
	}, []);

	return (
		<Box p={1}>
			<EntryList isLoading={isLoading} entries={entries} hasMore={hasMore} loadMore={loadNext} />
		</Box>
	);
}
