import React from 'react';
import useEntries from '../hooks/useEntries';
import EntryList from './entry/EntryList';
import Box from '@material-ui/core/Box';

export default function MostSecretEntries({ type }) {
	const { isLoading, hasMore, entries, loadNext } = useEntries({ type });

	return (
		<Box p={1}>
			<EntryList
				isLoading={isLoading}
				entries={entries}
				hasMore={hasMore}
				loadMore={loadNext}
				page={0}
			/>
		</Box>
	);
}
