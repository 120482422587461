import React from 'react';
import SectionHeading from '../../../common/SectionHeading';
import List from '@material-ui/core/List';
import ListItem from '../../../common/ListItem';
import { Box, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Cloud from '../../../common/Cloud';
import theme from '../../../theme';

const useStyle = makeStyles(theme => ({
	iconWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: 20,
		border: '1px solid ' + theme.textDark,
		minWidth: 'max-content',
		borderRadius: 100,
	},
	icon: {
		width: 25,
		height: 25,
		backgroundColor: theme.stobernBackground,
		borderRadius: 25,
	},
	list: {
		cursor: 'pointer',
	},
	box: {
		width: '50%',
		margin: '20px auto 0',
		[theme.breakpoints.down('md')]: {
			width: '90%',
		},
	},
}));

export default function FeedCategories({ categories, onClick: handleClick }) {
	const classes = useStyle();

	return (
		<Box className={classes.box}>
			<List className={classes.list}>
				<Cloud style={{ borderRadius: 7 }}>
					{categories.map((category, index) => (
						<ListItem
							key={category.id}
							divider={index !== categories.length - 1}
							onClick={() => handleClick(category.id)}
						>
							<ListItemIcon className={classes.iconWrapper}>
								<img src={category.icon} className={classes.icon} alt={category.title} />
							</ListItemIcon>
							<ListItemText primary={category.title} style={{ color: theme.textDark }} />
							<ArrowForwardIosIcon fontSize={'inherit'} style={{ color: theme.headerIcon }} />
						</ListItem>
					))}
				</Cloud>
			</List>
		</Box>
	);
}
