import { createAction, createReducer } from 'redux-starter-kit';
import AuthorizeService from '../../services/AuthorizeService';
import md5 from 'crypto-js/md5';
import ProfileService from '../../services/ProfileService';
import PointService from '../../services/PointService';
import ProfileSettingsService from '../../services/ProfileSettingsService';
import { jsonParser } from '../../helpers';
import { fetchSettings } from '../core/core.store';
import axios from 'axios';

export const loginRequest = createAction('PROFILE_LOGIN_REQUEST');
export const loginSuccess = createAction('PROFILE_LOGIN_SUCCESS');
export const loginFailure = createAction('PROFILE_LOGIN_FAILURE');
export const loginFailureSocial = createAction('PROFILE_LOGIN_SOCIAL_FAILURE');

export const createAccountRequest = createAction('PROFILE_CREATE_ACCOUNT_REQUEST');
export const createAccountFailure = createAction('PROFILE_CREATE_ACCOUNT_FAILURE');
export const createAccountSuccess = createAction('PROFILE_CREATE_ACCOUNT_SUCCESS');

export const deleteUserRequest = createAction('DELETE_USER_REQUEST');
export const deleteUserFailure = createAction('DELETE_USER_FAILURE');
export const deleteUserSuccess = createAction('DELETE_USER_SUCCESS');

export const logoutRequest = createAction('PROFILE_LOGOUT_REQUEST');
export const logoutSuccess = createAction('PROFILE_LOGOUT_SUCCESS');

export const fetchPointsRequest = createAction('FETCH_POINTS_REQUEST');
export const fetchPointsSuccess = createAction('FETCH_POINTS_SUCCESS');

export const togglePrivacySettingRequest = createAction('TOGGLE_PRIVACY_SETTING_REQUEST');
export const togglePrivacySettingFailure = createAction('TOGGLE_PRIVACY_SETTING_FAILURE');
export const togglePrivacySettingSuccess = createAction('TOGGLE_PRIVACY_SETTING_SUCCESS');

export const updateBirthdayRequest = createAction('UPDATE_BIRTHDAY_REQUEST');
export const updateBirthdayFailure = createAction('UPDATE_BIRTHDAY_FAILURE');
export const updateBirthdaySuccess = createAction('UPDATE_BIRTHDAY_SUCCESS');

export const updateGenderRequest = createAction('UPDATE_GENDER_REQUEST');
export const updateGenderFailure = createAction('UPDATE_GENDER_FAILURE');
export const updateGenderSuccess = createAction('UPDATE_GENDER_SUCCESS');

export const updateCountryRequest = createAction('UPDATE_COUNTRY_REQUEST');
export const updateCountryFailure = createAction('UPDATE_COUNTRY_FAILURE');
export const updateCountrySuccess = createAction('UPDATE_COUNTRY_SUCCESS');

export const updateZipRequest = createAction('UPDATE_ZIP_REQUEST');
export const updateZipFailure = createAction('UPDATE_ZIP_FAILURE');
export const updateZipSuccess = createAction('UPDATE_ZIP_SUCCESS');

export const updateSloganRequest = createAction('UPDATE_SLOGAN_REQUEST');
export const updateSloganFailure = createAction('UPDATE_SLOGAN_FAILURE');
export const updateSloganSuccess = createAction('UPDATE_SLOGAN_SUCCESS');

export const updateEmailRequest = createAction('UPDATE_MAIL_REQUEST');
export const updateEmailSuccess = createAction('UPDATE_MAIL_SUCCESS');
export const updateEmailFailure = createAction('UPDATE_MAIL_FAILURE');
export const updateEmailReset = createAction('UPDATE_MAIL_RESET');

export const registerRequest = createAction('REGISTER_REQUEST');
export const registerFailure = createAction('REGISTER_FAILURE');
export const registerSuccess = createAction('REGISTER_SUCCESS');

export const updateProfileField = createAction('UPDATE_PROFILE_FIELD');

export const setSocialCredentials = createAction('SET_SOCIAL_CREDENTIALS');

const initialState = {
	user: null,
	isLogging: true,
	isCreating: false,
	isLoggingOut: true,
	creationStatus: null,
	points: {
		sum: null,
		balance: {
			earned: null,
			bought: null,
		},
	},
	isTogglingSetting: false,
	isProfileUpdating: false,
	isRegistering: false,
	isDeleting: false,
	logInFail: false,
	socialLoginFailed: false,
	errorEmail: '',
	emailChanged: false,
	socialCredentials: null,
	isLoading: false
};

export const login = (mail, pass) => async dispatch => {
	const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

	localStorage.removeItem('token');
	dispatch(loginRequest());
	const passHash = md5(pass + '4500852acf9d074c0bf2c6f05556a106').toString();
	AuthorizeService.authorize(mail, passHash)
		.then(({ data }) => {
			localStorage.setItem('token', data.token);
			dispatch(
				loginSuccess({
					user: data.user,
				}),
			);
			ProfileService.timezoneUpdate(timeZone)
				.then(e => {
					console.log(e);
				})
				.finally((e) => {
					console.log('error', e);
					dispatch(fetchProfile());
				});
		})
		.catch(error => {
			dispatch(loginFailure());
		});
};

export const loginSocial = credentials => async dispatch => {
	localStorage.removeItem('token');
	dispatch(loginRequest());
	AuthorizeService.authorizeSocial(credentials.provider, credentials?.accessToken)
		.then(({ data }) => {
			localStorage.setItem('token', data.token);
			dispatch(
				loginSuccess({
					user: data.user,
				}),
			);
			dispatch(fetchSettings());
		})
		.catch(error => {
			dispatch(loginFailure());
			if (error.response.status && credentials?.accessToken) {
				if (credentials.provider === 'google') {
					const path = `https://oauth2.googleapis.com/tokeninfo?id_token=${credentials.accessToken}`;
					axios
						.get(path)
						.then(res => {
							dispatch(setSocialCredentials({ ...res.data, ...credentials }));
							dispatch(loginFailureSocial(true));
						})
						.catch(err => {
							console.log(err);
						});
				} else {
					dispatch(setSocialCredentials(credentials));
					dispatch(loginFailureSocial(true));
				}
			}
		});
};

export const createAccount =
	({ formikHelpers, ...data }) =>
	async dispatch => {
		dispatch(createAccountRequest());
		AuthorizeService.createRegistered({
			...data,
			HEIMLICHNICK: btoa(data.HEIMLICHNICK),
		})
			.then(({ data }) => {
				if (data.error === 'Benutzername ist bereits vergeben') {
					formikHelpers.setFieldError('HEIMLICHNICK', data.error);
					dispatch(createAccountFailure());
				} else if (data.error) {
					formikHelpers.setFieldError('mail', data.error);
					dispatch(createAccountFailure());
				} else {
					dispatch(createAccountSuccess());
					localStorage.setItem('token', data.token);
					dispatch(
						loginSuccess({
							user: data.user,
						}),
					);
					dispatch(fetchSettings());
				}
			})
			.catch(e => {
				console.log('eeee', e);
				formikHelpers.setFieldError('mail', e?.data?.error);
				dispatch(createAccountFailure());
			});
	};

export const deleteUser = () => dispatch => {
	dispatch(deleteUserRequest());
	ProfileService.deleteUser()
		.then(e => {
			dispatch(logoutRequest());
			dispatch(deleteUserSuccess());
			localStorage.removeItem('token');
		})
		.catch(() => {
			dispatch(deleteUserFailure());
		});
};

export const authorize = () => async dispatch => {
	const authToken = localStorage.getItem('token');
	if (!authToken) {
		dispatch(loginSuccess());
		return;
	}
	dispatch(loginRequest());
	ProfileService.me()
		.then(({ data }) => {
			dispatch(
				loginSuccess({
					user: data,
				}),
			);
		})
		.catch(() => {
			const mail = localStorage.getItem('mail');
			const pass = localStorage.getItem('pass');
			if (mail && pass) {
				dispatch(login(mail, pass));
				localStorage.removeItem('mail');
				localStorage.removeItem('pass');
			}
			dispatch(loginFailure());
		});
};

export const authorizeRemoteLogin = token => async dispatch => {
	AuthorizeService.remoteLogin(token)
		.then(({ data }) => {
			localStorage.setItem('token', data.token);
			dispatch(
				loginSuccess({
					user: data.user,
				}),
			);
			dispatch(fetchSettings());
		})
		.catch(() => {
			window.location.replace('/auth/guest&step=2');
		});
};

export const logout = () => async dispatch => {
	dispatch(logoutRequest());
	localStorage.removeItem('token');
	// window.location.replace('/guest&step=2?5');
};

export const fetchPoints = () => async dispatch => {
	dispatch(fetchPointsRequest());
	PointService.getPoints().then(({ data }) => {
		dispatch(
			fetchPointsSuccess({
				sum: +data.sum,
				balance: {
					earned: +data.balance.earned,
					bought: +data.balance.bought,
				},
			}),
		);
	});
};

export const fetchProfile = () => async dispatch => {
	ProfileService.me().then(({ data }) => {
		dispatch(
			loginSuccess({
				user: data,
			}),
		);
	});
};

export const togglePrivacySetting = settingName => async dispatch => {
	let requestPromise = Promise;
	if (settingName === 'hideliked') requestPromise = ProfileSettingsService.toggleHideLikes();
	if (settingName === 'hidecomments') requestPromise = ProfileSettingsService.toggleHideComments();
	if (settingName === 'allowchatrequests') requestPromise = ProfileSettingsService.toggleAllowChatRequests();
	if (settingName === 'allowmailnotifications') requestPromise = ProfileSettingsService.toggleAllowMailNotifications();
	if (settingName === 'hideadult') requestPromise = ProfileSettingsService.toggleAdultContent();

	dispatch(togglePrivacySettingRequest());
	requestPromise
		.then(() => {
			dispatch(fetchProfile());
			dispatch(togglePrivacySettingSuccess());
		})
		.catch(() => {
			dispatch(togglePrivacySettingFailure());
		});
};

export const updateProfileBirthday = birthday => async dispatch => {
	dispatch(updateBirthdayRequest());
	ProfileService.updateBirthday(birthday)
		.then(() => {
			dispatch(fetchProfile());
			dispatch(updateBirthdaySuccess());
		})
		.catch(() => {
			dispatch(updateBirthdayFailure());
		});
};

export const updateProfileGender = gender => dispatch => {
	dispatch(updateGenderRequest());
	ProfileService.updateGender(gender)
		.then(() => {
			dispatch(fetchProfile());
			dispatch(updateGenderSuccess());
		})
		.catch(() => {
			dispatch(updateGenderFailure());
		});
};

export const updateProfileCountry = country => dispatch => {
	dispatch(updateCountryRequest());
	ProfileService.updateCountry(country)
		.then(() => {
			dispatch(fetchProfile());
			dispatch(updateCountrySuccess());
		})
		.catch(() => {
			dispatch(updateCountryFailure());
		});
};

export const updateProfileZipCode = zip => dispatch => {
	dispatch(updateZipRequest());
	ProfileService.updateZipCode(zip)
		.then(() => {
			dispatch(fetchProfile());
			dispatch(updateZipSuccess());
		})
		.catch(() => {
			dispatch(updateZipFailure());
		});
};

export const updateProfileSlogan = slogan => dispatch => {
	dispatch(updateSloganRequest());
	ProfileService.updateSlogan(slogan)
		.then(() => {
			dispatch(fetchProfile());
			dispatch(updateSloganSuccess());
		})
		.catch(() => {
			dispatch(updateSloganFailure());
		});
};

export const updateProfileEmail = email => dispatch => {
	dispatch(updateEmailRequest());
	ProfileService.updateEmail(email)
		.then(() => {
			dispatch(updateEmailSuccess());
		})
		.catch(e => {
			dispatch(updateEmailFailure(e));
		});
};

export const register = (mail, pass) => async dispatch => {
	dispatch(registerRequest());
	ProfileService.register(mail, pass)
		.then(() => {
			dispatch(registerSuccess());
			dispatch(fetchProfile());
		})
		.catch(() => {
			dispatch(registerFailure());
		});
};

const profileReducer = createReducer(initialState, {
	// Login
	[loginRequest]: state => {
		state.isLogging = true;
	},
	[loginSuccess]: (state, action) => {
		state.isLogging = false;
		if (action?.payload?.user) {
			action.payload.user.entry_languages = jsonParser(action.payload.user.entry_languages) || [];
			state.user = action.payload.user;
		}
		state.logInFail = false;
	},
	[loginFailure]: state => {
		state.isLogging = false;
		state.logInFail = true;
	},
	// Create
	[createAccountRequest]: state => {
		state.isCreating = true;
		state.creationStatus = 'PENDING';
	},
	[createAccountSuccess]: state => {
		state.isCreating = false;
		state.creationStatus = 'DONE';
	},
	[createAccountFailure]: state => {
		state.isCreating = false;
		state.creationStatus = 'FAILURE';
	},
	// Logout
	[logoutRequest]: state => {
		state.isLoggingOut = true;
		state.user = null;
	},
	[logoutSuccess]: state => {
		state.isLoggingOut = false;
	},
	[loginFailureSocial]: (state, { payload }) => ({ ...state, socialLoginFailed: payload }),

	// Points
	[fetchPointsSuccess]: (state, action) => ({ ...state, points: action.payload }),

	// Privacy Setting
	[togglePrivacySettingRequest]: state => ({ ...state, isTogglingSetting: true }),
	[togglePrivacySettingFailure]: state => ({ ...state, isTogglingSetting: false }),
	[togglePrivacySettingSuccess]: state => ({ ...state, isTogglingSetting: false }),

	// Updating birthday
	[updateBirthdayRequest]: state => ({ ...state, isProfileUpdating: true }),
	[updateBirthdayFailure]: state => ({ ...state, isProfileUpdating: false }),
	[updateBirthdaySuccess]: state => ({ ...state, isProfileUpdating: false }),

	// Updating gender
	[updateGenderRequest]: state => ({ ...state, isProfileUpdating: true }),
	[updateGenderFailure]: state => ({ ...state, isProfileUpdating: false }),
	[updateGenderSuccess]: state => ({ ...state, isProfileUpdating: false }),

	// Updating country
	[updateCountryRequest]: state => ({ ...state, isProfileUpdating: true }),
	[updateCountryFailure]: state => ({ ...state, isProfileUpdating: false }),
	[updateCountrySuccess]: state => ({ ...state, isProfileUpdating: false }),

	// Updating zip
	[updateZipRequest]: state => ({ ...state, isProfileUpdating: true }),
	[updateZipFailure]: state => ({ ...state, isProfileUpdating: false }),
	[updateZipSuccess]: state => ({ ...state, isProfileUpdating: false }),

	// Updating slogan
	[updateSloganRequest]: state => ({ ...state, isProfileUpdating: true }),
	[updateSloganFailure]: state => ({ ...state, isProfileUpdating: false }),
	[updateSloganSuccess]: state => ({ ...state, isProfileUpdating: false }),

	[updateEmailRequest]: state => ({ ...state, isProfileUpdating: true }),
	[updateEmailFailure]: (state, { payload }) => ({
		...state,
		isProfileUpdating: false,
		errorEmail: payload?.data?.error,
	}),
	[updateEmailSuccess]: state => ({ ...state, isProfileUpdating: false, emailChanged: true }),
	[updateEmailReset]: state => ({ ...state, emailChanged: false }),

	// Registering
	[registerRequest]: state => ({ ...state, isRegistering: true }),
	[registerFailure]: state => ({ ...state, isRegistering: false }),
	[registerSuccess]: state => ({ ...state, isRegistering: false }),

	// Registering
	[registerRequest]: state => ({ ...state, isRegistering: true }),
	[registerFailure]: state => ({ ...state, isRegistering: false }),
	[registerSuccess]: state => ({ ...state, isRegistering: false }),
	[setSocialCredentials]: (state, { payload }) => ({
		...state,
		socialCredentials: payload,
	}),

	[updateProfileField]: (state, { payload: { field, value } }) => ({
		...state,
		user: {
			...state.user,
			[field]: value,
		},
	}),
});

export const currentUserSelector = state => state.profile.user;
export const isUserLoggingSelector = state => state.profile.isLogging;
export const pointsSelector = state => state.profile.points;
export const privacySettingsSelector = state => ({
	hideliked: state.profile.user.hideliked,
	hidecomments: state.profile.user.hidecomments,
	allowchatrequests: state.profile.user.allowchatrequests,
	allowmailnotifications: state.profile.user.allowmailnotifications,
	hideadult: state.profile.user.hideadult,
});

export const isRegisteredSelector = state => state.profile.user.registered;
export const isBirthdayChangedSelector = state => state.profile.user.birthday_changed;
export const isUserDeletingSelector = state => state.profile.isDeleting;

export default profileReducer;
