import React, { Fragment, useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Cloud from '../../../common/Cloud';
import ListItem from '../../../common/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { ListItemSecondaryAction, makeStyles, Typography } from '@material-ui/core';
import DoubleBalance from '../../shared/components/DoubleBalance';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchPoints,
	isRegisteredSelector,
	pointsSelector,
} from '../../../store/profile/profile.store';
import EarnedRewardsList from '../components/EarnedRewardsList';
import {
	earnedRewardsSelector,
	searchEarnedRewards,
	resetEarnedRewards,
} from '../../../store/points/points.store';
import { useHistory } from 'react-router-dom';
import RegisterListItem from '../../shared/components/RegisterListItem';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	root: {
		padding: 0,
	},
	primary: {
		fontWeight: 'bold',
		fontSize: 14,
		color: theme.textDark,
	},
	secondary: {
		fontSize: 13,
		color: theme.textLight,
	},
}));

export default function PointsScreen({ newRoutes, navFunction }) {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(fetchPoints());
	}, [dispatch]);

	const points = useSelector(pointsSelector);

	const classes = useStyle();

	const earnedRewards = useSelector(earnedRewardsSelector);
	const page = useSelector(state => state.points.page);
	const isLoading = useSelector(state => state.points.isLoading);
	const hasMore = useSelector(state => state.points.hasMore);
	const { t } = useTranslation();

	const loadProducer = useCallback(() => {
		dispatch(
			searchEarnedRewards({
				page,
			}),
		);
	}, [dispatch, page]);

	useEffect(() => {
		loadProducer();

		return () => {
			dispatch(resetEarnedRewards());
		};
	}, [dispatch]);

	const isRegistered = useSelector(isRegisteredSelector);
	const itemTextClasses = { primary: classes.primary, secondary: classes.secondary };
	return (
		<div>
			<Box p={[2]}>
				<List component={Cloud} style={{ backgroundColor: theme.mainBackgroundScreens }}>
					<ListItem classes={classes} divider>
						<ListItemText
							classes={itemTextClasses}
							primary={t('my points')}
							secondary={t('from which earned points')}
						/>
						<ListItemSecondaryAction>
							<DoubleBalance
								one={points.balance.earned + points.balance.bought}
								two={points.balance.earned}
							/>
						</ListItemSecondaryAction>
					</ListItem>
					{isRegistered ? (
						<Fragment>
							<ListItem
								classes={classes}
								button
								divider
								onClick={() => navFunction(newRoutes[0]?.screenName, newRoutes[0]?.label)}
							>
								<ListItemText
									classes={itemTextClasses}
									primary={t('my affiliate link2')}
									secondary={t('earn by install')}
								/>
								<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
							</ListItem>
							<ListItem
								classes={classes}
								button
								divider
								onClick={() => navFunction(newRoutes[1]?.screenName, newRoutes[1]?.label)}
							>
								<ListItemText
									classes={itemTextClasses}
									primary={t('redeem points')}
									secondary={t('redeem your points')}
								/>
								<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
							</ListItem>
							<ListItem
								classes={classes}
								button
								onClick={() => navFunction(newRoutes[2]?.screenName, newRoutes[2]?.label)}
							>
								<ListItemText
									classes={itemTextClasses}
									primary={t('buy points')}
									secondary={t('you can buy points')}
								/>
								<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
							</ListItem>
						</Fragment>
					) : (
						<Fragment>
							<RegisterListItem divider />
							<ListItem
								classes={classes}
								button
								onClick={() => history.push('/profile/options/how-it-works')}
							>
								<ListItemText primary={'Wie geht das?'} />
								<KeyboardArrowRightIcon style={{ color: theme.headerIcon }} />
							</ListItem>
						</Fragment>
					)}
				</List>

				{isRegistered ? (
					<Fragment>
						<Box pt={1}>
							<Typography style={{ color: theme.textDark }} variant={'subtitle2'}>
								{t('my rewards')} ({t('refresh each hour')})
							</Typography>
						</Box>
						<Box>
							<EarnedRewardsList
								data={earnedRewards}
								isLoading={isLoading}
								loadProducer={loadProducer}
								hasMore={hasMore}
							/>
						</Box>
					</Fragment>
				) : null}
			</Box>
		</div>
	);
}
