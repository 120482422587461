import React, { useMemo, useState } from 'react';
import UserAvatar from '../../../shared/components/UserAvatar';
import { Box, makeStyles, Typography, List, Dialog, ListItemText } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { ENTRY_ACTION } from '../../../../constants';
import ListItem from '../../../../common/ListItem';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../../store/profile/profile.store';
import Modal from '@material-ui/core/Modal';
import ReportEntryScreen from '../../screens/ReportEntryScreen';
import theme from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyle = makeStyles(theme => ({
	nickname: {
		fontSize: 14,
		color: theme.mainBlue,
		fontWeight: 'bold',
	},
	ageAndGender: {
		fontSize: 14,
		color: theme.age,
		fontWeight: 'bold',
		marginLeft: 5,
	},
	actionsButton: {
		position: 'relative',
		cursor: 'pointer',
	},
	nameGander: {
		height: 25,
		display: 'flex',
		marginLeft: 6,
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		height: '90%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));

export default function EntryItemHeader({
	author: { avatar, nickname, gender, age },
	deletePost: handleDeletePost,
	onReportEntry: handleReportEntry,
	onBlockEntry: handleBlockEntry,
	onGoToProfile: handleGoToProfile,
	onGoToAuthor: handleGoToAuthor,
	onShare: handleShare,
	uidUser,
	id,
	isProfile,
}) {
	const classes = useStyle();
	const { t } = useTranslation();

	const [actionsDialogVisible, setActionsDialogVisible] = useState(false);
	const handleDialogClose = () => setActionsDialogVisible(false);
	const currentUser = useSelector(currentUserSelector);
	const [open, setOpen] = useState(false);
	const [blockingDialogVisible, setBlockingDialogVisible] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const handleBlock = () => {
		handleBlockEntry();
		setBlockingDialogVisible(false);
	};

	const handleAction = entryAction => {
		handleDialogClose();
		switch (entryAction) {
			case ENTRY_ACTION.DELETE_POST:
				handleDeletePost();
				break;
			case ENTRY_ACTION.REPORT_ENTRY:
				handleOpen();
				break;
			case ENTRY_ACTION.BLOCK_ENTRY:
				setBlockingDialogVisible(true);
				break;
			case ENTRY_ACTION.GO_TO_PROFILE:
				handleGoToProfile();
				break;
			case ENTRY_ACTION.SHARE:
				handleShare();
				break;
			default:
				break;
		}
	};

	const actions = useMemo(
		() => [
			{
				key: ENTRY_ACTION.REPORT_ENTRY,
				label: t('report post'),
				isShow: !(uidUser === currentUser?.uid),
			},
			{
				key: ENTRY_ACTION.BLOCK_ENTRY,
				label: t('block post'),
				isShow: !(uidUser === currentUser?.uid),
			},
			{
				key: ENTRY_ACTION.GO_TO_PROFILE,
				label: `${nickname}'s ${t('profile')}`,
				isShow: !(uidUser === currentUser?.uid),
			},
			{
				key: ENTRY_ACTION.SHARE,
				label: navigator.share ? t('share') : t('copy link'),
				isShow: true,
			},
			{
				key: ENTRY_ACTION.DELETE_POST,
				label: t('delete post'),
				isShow: uidUser === currentUser?.uid,
			},
		],
		[nickname],
	);

	return (
		<Box p={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
			<Box
				style={{ cursor: uidUser && 'pointer' }}
				display={'flex'}
				alignItems={'center'}
				justifyContent={'center'}
				onClick={handleGoToAuthor}
			>
				<UserAvatar size={20} src={avatar} withBorder={false} />
				<Box className={classes.nameGander}>
					<Typography className={classes.nickname}>{nickname}</Typography>
					{gender && (
						<Typography className={classes.ageAndGender}>
							{age}
							{t('profile age')}{' '}
							<FontAwesomeIcon
								icon={gender === 'w' ? faVenus : faMars}
								className={classes.icons}
								color={gender === 'w' ? '#FF00FF' : 'rgb(35,79,181)'}
							/>
						</Typography>
					)}
				</Box>
			</Box>
			<Dialog open={blockingDialogVisible} onClose={() => setBlockingDialogVisible(false)}>
				<DialogContent>
					<DialogContentText>{t('confirm block')}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setBlockingDialogVisible(false)} color="primary">
						{t('cancel')}
					</Button>
					<Button onClick={handleBlock} color="primary" autoFocus>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog onClose={handleDialogClose} open={actionsDialogVisible}>
				{actionsDialogVisible ? (
					<List>
						{actions.map((action, index) => {
							return action.isShow ? (
								<ListItem
									key={action.key}
									button
									onClick={() => handleAction(action.key)}
									divider={true}
								>
									<ListItemText primary={action.label} />
								</ListItem>
							) : null;
						})}
						<ListItem button onClick={handleDialogClose} divider={false}>
							<ListItemText primary={t('cancel')} style={{ color: 'red' }} />
						</ListItem>
					</List>
				) : (
					<div />
				)}
			</Dialog>

			<Box
				className={classes.actionsButton}
				onClick={() => setActionsDialogVisible(true)}
				role="button"
				title={t('more')}
			>
				<MoreHorizIcon style={{ color: theme.mainBlue }} />
			</Box>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<ReportEntryScreen reportId={id} onClose={() => handleClose()} />
				</Box>
			</Modal>
		</Box>
	);
}
