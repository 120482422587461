import React from 'react';
import Box from '@material-ui/core/Box';
import Cloud from '../../../common/Cloud';
import ListItem from '../../../common/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
	privacySettingsSelector,
	togglePrivacySetting,
} from '../../../store/profile/profile.store';
import Switch from '@material-ui/core/Switch';
import Loader from '../../../common/Loader';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles(theme => ({
	item: {
		paddingLeft: 0,
		paddingRight: 0,
	},
	primary: {
		fontWeight: 'bold',
		fontSize: 14,
		color: theme.textDark,
	},
	secondary: {
		fontSize: 13,
		color: theme.textLight,
	},
}));

export default function PrivacyScreen() {
	const dispatch = useDispatch();
	const classes = useStyle();

	const itemTextClasses = { primary: classes.primary, secondary: classes.secondary };
	const privacySettings = useSelector(privacySettingsSelector);
	const isTogglingSetting = useSelector(state => state.profile.isTogglingSetting);
	const { t } = useTranslation();
	const toggleSetting = settingName => dispatch(togglePrivacySetting(settingName));
	return (
		<div>
			<Box p={[2]}>
				<List component={Cloud} style={{ backgroundColor: theme.mainBackgroundScreens }}>
					<ListItem classes={{ root: classes.item }} divider>
						<ListItemText
							classes={itemTextClasses}
							primary={t('voted posts')}
							secondary={t('show voted posts')}
						/>
						<Switch
							checked={privacySettings.hideliked}
							onChange={() => toggleSetting('hideliked')}
							value={true}
						/>
					</ListItem>
					<ListItem classes={{ root: classes.item }} divider>
						<ListItemText
							classes={itemTextClasses}
							primary={t('show comments')}
							secondary={t('show commented posts')}
						/>
						<Switch
							checked={privacySettings.hidecomments}
							onChange={() => toggleSetting('hidecomments')}
							value={true}
						/>
					</ListItem>
					<ListItem classes={{ root: classes.item }}>
						<ListItemText
							classes={itemTextClasses}
							primary={t('allow chat')}
							secondary={t('allow chat requests')}
						/>
						<Switch
							checked={privacySettings.allowchatrequests}
							onChange={() => toggleSetting('allowchatrequests')}
							value={true}
						/>
					</ListItem>
					<ListItem classes={{ root: classes.item }}>
						<ListItemText
							classes={itemTextClasses}
							primary={t('email notifications')}
							secondary={t('allow email notification')}
						/>
						<Switch
							checked={privacySettings.allowmailnotifications}
							onChange={() => toggleSetting('allowmailnotifications')}
							value={true}
						/>
					</ListItem>
					<ListItem classes={{ root: classes.item }}>
						<ListItemText
							classes={itemTextClasses}
							primary={t('allow adult content')}
							secondary={t('allow adult content text')}
						/>
						<Switch
							checked={privacySettings.hideadult}
							onChange={() => toggleSetting('hideadult')}
							value={true}
						/>
					</ListItem>
				</List>
			</Box>

			{isTogglingSetting ? <Loader /> : null}
		</div>
	);
}
