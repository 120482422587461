import React, { useEffect, useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HeaderRight from '../../shared/components/HedaerRight';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import { logout } from '../../../store/profile/profile.store';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';
import {
	faChevronRight,
	faUser,
	faEnvelope,
	faHandshake,
	faCoins,
	faShieldAlt,
	faHeadset,
	faQuestionCircle,
	faIdBadge,
	faMoneyBill,
	faFileInvoiceDollar,
	faSun,
	faMoon,
	faSignOutAlt,
	faBars,
	faPencilAlt,
	faGlobe,
	faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ManageProfileScreen from '../../profile/screens/ManageProfileScreen';
import PartnerLinkScreen from '../../options/screens/PartnerLinkScreen';
import PointsScreen from '../../options/screens/PointsScreen';
import PrivacyScreen from '../../options/screens/PrivacyScreen';
import ContactScreen from '../../options/screens/ContactScreen';
import FAQScreen from '../../options/screens/FAQScreen';
import { AnimatorScreen } from '../../animator/screens/AnimatorScreen';
import PackageBuyMethodScreen from '../../options/screens/package/PackageBuyMethodScreen';
import RedeemPointsScreen from '../../options/screens/RedeemPointsScreen';
import theme from '../../../theme';
import Collapse from '@material-ui/core/Collapse';
import OutsideClickHandler from 'react-outside-click-handler';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { darkModeHandler, darkModeSelector } from '../../../store/theme/theme.store';
import { useTranslation } from 'react-i18next';
import ChangeLanguage from '../../options/screens/ChangeLanguage';
import LegalsScreen from '../../options/screens/LegalsScreen';
import EditProfileScreen from '../../profile/screens/EditProfileScreen';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			paddingBottom: 60,
		},
	},
	indicator: {
		backgroundColor: 'transparent',
		justifyContent: 'center',
	},
	tabStyle: {
		backgroundColor: theme.mainBlue,
		borderRadius: 7,
		margin: '0 50px',
		minHeight: 'auto',
		color: theme.white,
		padding: '4px 60px',
		marginTop: 25,
	},
	logo: {
		height: 40,
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	addPost: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '7.5px 15px',
		marginLeft: 10,
		backgroundColor: theme.mainBlue,
		borderRadius: 15,
		color: theme.white,
	},
	addPostText: {
		fontSize: 15,
	},
	addPostIcon: {
		size: 22,
		marginLeft: 5,
	},
	modalItems: {
		paddingLeft: 5,
		fontSize: 17,
		lineHeight: '20px',
		color: theme.textLight,
	},
	'&$checked': {
		color: 'green',
	},
	headerTitle: {
		flex: 2,
		textAlign: 'center',
		fontSize: 20,
		marginTop: 30,
		marginBottom: 20,
		color: theme.headerIcon,
	},
	listItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: '#555',
		padding: '15px 20px 10px',
		'&:hover > *, &:active, &:focus': {
			color: '#FFF',
		},
		'&:hover, &:active, &:focus': {
			backgroundColor: alpha(theme.mainBlue, 0.8),
		},
	},
	icons: {
		fontSize: 20,
		color: '#ddd',
	},
	iconsArrow: {
		fontSize: 13,
		color: '#555',
	},
	leftItem: {
		display: 'flex',
		flexDirection: 'row',
	},
	profileEdit: {
		display: 'flex',
		flexDirection: 'row',
		padding: '0 50px',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			width: '100%',
			padding: 0,
			alignItems: 'center',
		},
	},
	profileEditLeft: {
		borderRadius: 5,
		backgroundColor: theme.mainBackgroundScreens,
		color: '#000',
		flex: 4,
		maxWidth: 290,
		cursor: 'pointer',
		border: '2px solid',
		borderColor: theme.borderContainers,
		overflow: 'hidden',
		height: 'max-content',
		[theme.breakpoints.down('md')]: {
			marginTop: 10,
			flex: 1,
			maxWidth: 'none',
			width: '80%',
		},
	},
	profileEditRight: {
		backgroundColor: theme.mainBackgroundScreens,
		flex: 9,
		marginLeft: 35,
		[theme.breakpoints.down('md')]: {
			flex: 1,
			width: '100%',
			alignSelf: 'center',
			marginLeft: 0,
		},
	},
	leftIconBox: {
		width: 30,
	},
	activeShadow: {
		boxShadow: '0 0 8px 1px rgb(0 0 0 / 60%)',
	},
	menuButton: {
		[theme.breakpoints.down('md')]: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			alignSelf: 'center',
			color: theme.white,
			backgroundColor: theme.mainBlue,
			padding: '10px 0',
			borderRadius: 100,
		},
	},
	menuButtonIsShow: {
		display: 'none',
		[theme.breakpoints.down('md')]: {
			display: 'block',
			width: '85%',
			alignSelf: 'center',
			'& > div': {
				width: '100%',
			},
		},
	},
	menuButtonText: {
		marginTop: 2,
		marginLeft: 5,
	},
	linkText: {
		color: theme.textLight,
		textDecoration: 'none',
	},
	primary: {
		fontSize: 12,
		color: theme.textLight,
	},
}));

export default function ProfileEditScreen() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const [checkedItem, setCheckedItem] = useState('');
	const [headerName, setHeaderName] = useState('');
	const [logOutDialog, setLogOutDialog] = useState(false);
	const [showScreen, setShowScreen] = useState(false);
	const { width } = useWindowSize();
	const darkMode = useSelector(darkModeSelector);
	const { t } = useTranslation();
	useEffect(() => {
		history?.location?.state?.label && setCheckedItem(history?.location?.state?.label);
		history?.location?.state?.name && setHeaderName(history?.location?.state?.name);
	}, []);

	const onLogOut = () => dispatch(logout());
	const menuList = [
		{
			label: t('my profile'),
			iconName: faUser,
			screenName: 'MainProfil',
			arrowIcon: false,
			iconColor: '',
			newFunction: () => history.push('/profile'),
		},
		{
			label: t('edit profile'),
			iconName: faPencilAlt,
			screenName: 'EditProfileScreen',
			arrowIcon: true,
		},
		{
			label: t('my affiliate link'),
			iconName: faHandshake,
			screenName: 'PartnerLinkScreen',
			arrowIcon: true,
		},
		{
			label: t('my points'),
			iconName: faCoins,
			screenName: 'PointsScreen',
			arrowIcon: true,
		},
		{
			label: t('privacy'),
			iconName: faShieldAlt,
			screenName: 'PrivacyScreen',
			arrowIcon: true,
		},
		{
			label: t('contact'),
			iconName: faHeadset,
			screenName: 'ContactScreen',
			arrowIcon: true,
		},
		{
			label: 'FAQ',
			iconName: faQuestionCircle,
			screenName: 'FAQScreen',
			arrowIcon: true,
		},
		{
			label: t('become entertainer'),
			iconName: faIdBadge,
			screenName: 'AnimatorScreen',
			arrowIcon: true,
		},
		{
			label: t('buy points'),
			iconName: faMoneyBill,
			screenName: 'PackageBuyMethodScreen',
			arrowIcon: true,
		},
		{
			label: t('redeem points'),
			iconName: faFileInvoiceDollar,
			screenName: 'RedeemPointsScreen',
			arrowIcon: true,
		},
		{
			label: t('select language'),
			iconName: faGlobe,
			screenName: 'ChangeLanguage',
			arrowIcon: true,
		},
		{
			label: darkMode ? t('light mode') : t('dark mode'),
			iconName: darkMode ? faSun : faMoon,
			screenName: 'DarkMode',
			arrowIcon: false,
			iconColor: darkMode ? 'gold' : '#183351',
			newFunction: () => {
				dispatch(darkModeHandler(darkMode ? 0 : 1));
			},
		},
		{
			label: t('legals'),
			iconName: faInfoCircle,
			screenName: 'LegalsScreen',
			arrowIcon: true,
		},
		{
			label: t('logout'),
			iconName: faSignOutAlt,
			screenName: 'LogOut',
			arrowIcon: false,
			newFunction: () => setLogOutDialog(true),
		},
	];

	const screens = {
		PartnerLinkScreen: <PartnerLinkScreen />,
		PointsScreen: (
			<PointsScreen
				newRoutes={[
					{
						label: t('my affiliate link'),
						screenName: 'PartnerLinkScreen',
					},
					{
						label: t('redeem points'),
						screenName: 'RedeemPointsScreen',
					},
					{
						label: t('buy points'),
						screenName: 'PackageBuyMethodScreen',
					},
				]}
				navFunction={(screenName, label) => changeItemAndHeader(screenName, label)}
			/>
		),
		EditProfileScreen: <EditProfileScreen />,
		PrivacyScreen: <PrivacyScreen />,
		ContactScreen: <ContactScreen />,
		FAQScreen: <FAQScreen />,
		AnimatorScreen: <AnimatorScreen />,
		PackageBuyMethodScreen: <PackageBuyMethodScreen />,
		RedeemPointsScreen: <RedeemPointsScreen />,
		ChangeLanguage: <ChangeLanguage />,
		LegalsScreen: <LegalsScreen />,
	};

	const changeItemAndHeader = (name, label) => {
		setCheckedItem(name);
		setHeaderName(label);
	};

	return (
		<div className={classes.root}>
			<HeaderAppBar
				leftIcon={
					<img
						src={theme.smallLogo}
						alt={'logo'}
						className={classes.logo}
						onClick={() => history.push('/feed')}
						color={'action'}
					/>
				}
				rightIcon={<HeaderRight changeItem={changeItemAndHeader} />}
				changeItemFunction={changeItemAndHeader}
				newPost
				isLogo
				bar
			/>
			<div className={classes.headerTitle}>{t(headerName)}</div>
			<div className={classes.profileEdit}>
				<div className={classes.menuButtonIsShow}>
					<OutsideClickHandler
						onOutsideClick={() => {
							width < 600 && setShowScreen(false);
						}}
					>
						<div className={classes.menuButton} onClick={() => setShowScreen(!showScreen)}>
							<FontAwesomeIcon icon={faBars} color={theme.white} />
							<div className={classes.menuButtonText}>Menu</div>
						</div>
					</OutsideClickHandler>
				</div>

				<Box
					className={classes.profileEditLeft}
					style={{ borderWidth: !showScreen && width < 600 && 0 }}
				>
					<Collapse
						in={width > 600 ? true : showScreen}
						timeout={400}
						disableStrictModeCompat={true}
					>
						{menuList.map((item, index) => {
							return (
								<div
									className={`${classes.listItem} ${
										item?.screenName === checkedItem ? classes.activeShadow : ''
									}`}
									style={
										item?.screenName === checkedItem
											? { backgroundColor: alpha(theme.mainBlue, 1) }
											: {}
									}
									onClick={
										item?.newFunction
											? () => item?.newFunction()
											: () => changeItemAndHeader(item?.screenName, item?.label)
									}
								>
									<div className={classes.leftItem}>
										<div className={classes.leftIconBox}>
											<FontAwesomeIcon
												icon={item?.iconName}
												className={classes.icons}
												style={
													item?.iconColor
														? { color: item?.iconColor }
														: item?.screenName === checkedItem
														? { color: 'white' }
														: {}
												}
											/>
										</div>
										<div
											className={classes.modalItems}
											style={item?.screenName === checkedItem ? { color: 'white' } : {}}
										>
											{t(item?.label)}
										</div>
									</div>
									{item?.arrowIcon && (
										<FontAwesomeIcon
											icon={faChevronRight}
											className={classes.iconsArrow}
											style={item?.screenName === checkedItem ? { color: 'white' } : {}}
										/>
									)}
								</div>
							);
						})}
					</Collapse>
				</Box>

				<Box className={classes.profileEditRight}>{screens[checkedItem]}</Box>
			</div>
			<Dialog open={logOutDialog} onClose={() => setLogOutDialog(false)}>
				<DialogContent>
					<DialogContentText>{t('confirm logout')}</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{
						justifyContent: 'center',
					}}
				>
					<Button onClick={() => setLogOutDialog(false)}>{t('cancel')}</Button>
					<Button onClick={() => onLogOut()} variant="contained" color="primary" autoFocus>
						ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
