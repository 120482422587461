import React, { Fragment, useState } from 'react';
import HeaderAppBar from '../../shared/components/HeaderAppBar';
import { useHistory } from 'react-router-dom';
import uploadService from '../../../services/UploadService';
import MediaForm from '../../shared/components/MediaForm';
import BottomToast from '../../../common/BottomToast';
import BackNavigationIcon from '../../shared/components/BackNavigationIcon';
import Loader from '../../../common/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import Box from '@material-ui/core/Box';
import ManageProfileSuccessScreen from '../../profile/screens/ManageProfileSuccessScreen';
import Modal from '@material-ui/core/Modal';
import CreatePostSuccessScreen from './CreatePostSuccessScreen';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
}));
export default function CreatePostScreen({ onClose }) {
	const classes = useStyles();
	const { t } = useTranslation();

	const [isSending, setIsSending] = useState(false);
	const [shareSocial, setShareSocial] = useState(true);
	const [message, setMessage] = useState(null);
	const [mediaType, setMediaType] = useState(null);
	const [mediaFile, setMediaFile] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [isNoContentToast, setIsNoContentToast] = useState(false);
	const [isMinText, setIsMinText] = useState(false);
	const [languageCode, setLanguageCode] = useState('');

	function handleFileChange(type, event) {
		setMediaType(type);
		if (event.target.files.length > 0) {
			setMediaFile(event.target.files[0]);
		} else {
			setMediaFile(null);
		}
	}

	const handleShareSocialChange = () => setShareSocial(shareSocial => !shareSocial);

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);
	const handleGetLanguageCode = lang => setLanguageCode(lang);

	const handleSubmit = () => {
		if (!(mediaFile || message)) {
			setIsNoContentToast(true);
			return;
		}
		if(message && (message?.length < 50)){
			setIsMinText(true)
			return;
		}
		const data = {
			language: languageCode,
			text: message,
			shareSocial,
		};
		if (mediaType === 'image') {
			data.image = mediaFile;
		} else if (mediaType === 'video') {
			data.video = mediaFile;
		}
		setIsSending(true);
		uploadService
			.uploadPost(data)
			.then(({ data }) => {
				if (data.success) {
					handleOpen();
				}
			})
			.finally(() => setIsSending(false));
	};

	const handleTextChange = event => {
		event.persist();
		setMessage(event.target.value);
	};

	return (
		<Fragment>
			<HeaderAppBar
				title={t('add entry')}
				leftIcon={
					<FontAwesomeIcon
						onClick={onClose}
						icon={faLongArrowAltLeft}
						style={{ fontSize: 25 }}
						color={theme.headerIcon}
					/>
				}
			/>
			{isSending ? <Loader /> : null}
			<MediaForm
				onTextChange={handleTextChange}
				onFileChange={handleFileChange}
				onShareSocialChange={handleShareSocialChange}
				onSubmit={handleSubmit}
				mediaType={mediaType}
				shareSocial={shareSocial}
				handleGetLanguageCode={handleGetLanguageCode}
				flow={'post'}
			/>
			<BottomToast open={isNoContentToast} onClose={() => setIsNoContentToast(false)}>
				{t('error no content')}
			</BottomToast>
			<BottomToast open={isMinText} onClose={() => setIsMinText(false)}>
				{t('minimum 50 characters')}
			</BottomToast>
			<Modal
				open={openModal}
				onClose={handleClose}
				onBackdropClick={onClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				className={classes.modalBack}
			>
				<Box className={classes.modal}>
					<CreatePostSuccessScreen onClose={onClose} />
				</Box>
			</Modal>
		</Fragment>
	);
}
