import BaseService from './BaseService';

class UploadService extends BaseService {
	constructor() {
		super('/upload');
	}

	uploadPost({ text, image, video, shareSocial, language }) {
		const form = new FormData();
		const messageKey = image ? 'message' : 'description';

		if (image) {
			form.append('image', image);
		} else if (video) {
			form.append('video', video);
		}

		form.append(messageKey, text);
		form.append('shareSocial', Number(shareSocial));
		form.append('copyrightWarranted', '1');
		// form.append("language", language);

		let promise = null;

		if (image) {
			promise = this.post('/image', form);
		} else if (video) {
			promise = this.post('/video', form);
		} else {
			promise = this.post('/text', form);
		}

		return promise;
	}
}

export default new UploadService();
