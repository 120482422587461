import React, { useState } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBell,
	faChevronDown,
	faHome,
	faComment,
	faUsers,
} from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { useHistory } from 'react-router-dom';
import MyAvatar from './MyAvatar';
import { logout } from '../../../store/profile/profile.store';
import { useDispatch, useSelector } from 'react-redux';
import {
	Badge,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@material-ui/core';
import NewPost from './NewPost';
import Divider from '@material-ui/core/Divider';
import {
	unreadConversationsSelector,
	unreadNotificationsSelector,
} from '../../../store/chat/chat.store';
import { darkModeHandler, darkModeSelector } from '../../../store/theme/theme.store';
import OutsideClickHandler from 'react-outside-click-handler';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.white,
	},
	toolbar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	icons: {
		fontSize: 25,
		marginRight: 15,
		cursor: 'pointer',
	},
	arrowDown: {
		fontSize: 12,
		marginBottom: 3,
		marginLeft: 3,
	},
	user: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	addPost: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		padding: '7.5px 15px',
		marginLeft: 10,
		backgroundColor: theme.mainBlue,
		borderRadius: 15,
		color: theme.white,
	},
	addPostText: {
		fontSize: 15,
	},
	addPostIcon: {
		size: 22,
		marginLeft: 5,
	},
	modalUser: {
		position: 'absolute',
		top: 64,
		marginLeft: 42,
		borderRadius: 3,
		boxShadow: '0px -4px 12px 5px rgba(0,0,0,0.14)',
		backgroundColor: theme.mainBackgroundScreens,
		color: '#000',
		padding: '5px 0',
		zIndex: 2000,
	},
	topBot: {
		position: 'absolute',
		top: -11.5,
		right: 8,
		width: 0,
		height: 0,
		borderWidth: 5,
		borderStyle: 'solid',
		borderColor: 'transparent transparent ' + theme.mainBackgroundScreens + ' transparent',
	},
	topBotBorder: {
		top: -9.5,
		borderColor: 'transparent transparent ' + theme.mainBackgroundScreens + ' transparent',
	},
	modalItems: {
		padding: '5px 20px',
		color: theme.textDark,
		cursor: 'pointer',
		'&:hover, &:active, &:focus': {
			backgroundColor: alpha(theme.mainBlue, 1),
			color: '#FFF',
		},
	},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '60%',
		height: '80%',
		backgroundColor: theme.mainBackgroundScreens,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
		},
	},
	modalBack: {
		backgroundColor: theme.modalColor,
	},
	'&$checked': {
		color: 'green',
	},
}));

export default function HeaderRight({ changeItem }) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [userModal, setUserModal] = useState(false);
	const [logOutDialog, setLogOutDialog] = useState(false);
	const onLogOut = () => dispatch(logout());
	const changeRoute = (name, label) => {
		setUserModal(false);
		if (history.location.pathname === '/profile-edit') {
			changeItem(label, name);
		}
		history.replace({ pathname: '/profile-edit', state: { name: name, label: label } });
	};
	const unreadConversations = useSelector(unreadConversationsSelector);
	const unreadNotifications = useSelector(unreadNotificationsSelector);
	const darkMode = useSelector(darkModeSelector);

	const setMode = () => {
		setUserModal(false);
		dispatch(darkModeHandler(darkMode ? 0 : 1));
	};
	return (
		<div className={classes.toolbar}>
			<FontAwesomeIcon
				onClick={() => history.push('/feed')}
				icon={faHome}
				className={classes.icons}
				color={theme.headerIcon}
				title={t('home')}
				role="button"
			/>

			<FontAwesomeIcon
				onClick={() => history.push('/community')}
				icon={faUsers}
				className={classes.icons}
				color={theme.headerIcon}
				title={t('community')}
				role="button"
			/>
			<Badge
				badgeContent={unreadConversations}
				onClick={() => history.push('/conversations')}
				color="primary"
				title={t('messages')}
				role="button"
			>
				<FontAwesomeIcon icon={faComment} className={classes.icons} color={theme.headerIcon} />
			</Badge>
			<Badge
				badgeContent={unreadNotifications}
				onClick={() => history.push('/notifications')}
				color="primary"
				title={t('notifications')}
				role="button"
			>
				<FontAwesomeIcon
					icon={faBell}
					className={classes.icons}
					color={theme.headerIcon}
					style={{ marginLeft: unreadConversations ? 15 : 0 }}
				/>
			</Badge>
			<div
				className={classes.user}
				onClick={() => setUserModal(!userModal)}
				style={{ marginLeft: unreadNotifications ? 15 : 0 }}
				title={t('menu')}
				role="button"
			>
				<MyAvatar size={30} withBorder={false} />
				<FontAwesomeIcon
					icon={faChevronDown}
					className={classes.arrowDown}
					color={theme.headerIcon}
				/>
			</div>

			<NewPost title={t('new')} />
			{userModal && (
				<div className={classes.modalUser}>
					<OutsideClickHandler onOutsideClick={() => setUserModal(false)}>
						<div className={classes.topBot} />
						<div className={`${classes.topBot} ${classes.topBotBorder}`} />
						<div className={classes.modalItems} onClick={() => history.push('/profile')}>
							{t('my profile')}
						</div>
						<Divider />
						<div
							className={classes.modalItems}
							onClick={() => changeRoute(t('edit profile'), 'EditProfileScreen')}
						>
							{t('edit profile')}
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute(t('my affiliate link'), 'PartnerLinkScreen')}
						>
							{t('my affiliate link')}
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute(t('my points'), 'PointsScreen')}
						>
							{t('my points')}
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute(t('privacy'), 'PrivacyScreen')}
						>
							{t('privacy')}
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute(t('contact'), 'ContactScreen')}
						>
							{t('contact')}
						</div>
						<div className={classes.modalItems} onClick={() => changeRoute('FAQ', 'FAQScreen')}>
							FAQ
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute(t('become entertainer'), 'AnimatorScreen')}
						>
							{t('real entertainer')}
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute(t('buy points'), 'PackageBuyMethodScreen')}
						>
							{t('buy points')}
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute(t('redeem points'), 'RedeemPointsScreen')}
						>
							{t('redeem points')}
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute('select language', 'ChangeLanguage')}
						>
							{t('select language')}
						</div>
						<div
							className={classes.modalItems}
							onClick={() => changeRoute('legals', 'LegalsScreen')}
						>
							{t('legals')}
						</div>
						<Divider />
						<div className={classes.modalItems} onClick={() => setMode()}>
							{darkMode ? t('light mode') : t('dark mode')}
						</div>
						<Divider />
						<div className={classes.modalItems} onClick={() => setLogOutDialog(true)}>
							Logout
						</div>
					</OutsideClickHandler>
				</div>
			)}
			<Dialog open={logOutDialog} onClose={() => setLogOutDialog(false)}>
				<DialogContent>
					<DialogContentText>{t('confirm logout')}</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{
						justifyContent: 'center',
					}}
				>
					<Button onClick={() => setLogOutDialog(false)}>{t('cancel')}</Button>
					<Button onClick={() => onLogOut()} variant="contained" color="primary" autoFocus>
						ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
